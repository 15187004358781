import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Table, Input, Button, Popconfirm, Form } from "antd";
import StateDropdown from "./../CommonComponents/StateDropdown";
import DropdownNationalitate from "./../CommonComponents/DropdownNationalitate";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import DownloadComponent from "./../CommonComponents/DownloadComponent";
import CustomAncheta from "./../Ancheta/CustomAncheta";
import moment from "moment";
import { Typography } from "@material-ui/core/";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import mystyle from "./../mystyle.module.css";
import CustomRecomandareMedic from "./../CommonComponents/CustomRecomandareMedic";
import DownloadErrorComponent from "./DownloadErrorComponent";
import Tooltip from "@material-ui/core/Tooltip";
import StatusAnchetaDropdown from "./StatusAnchetaDropdown";
import DateSelect from "./../CommonComponents/DateSelect";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="editing"
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onDoubleClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class TabelCazuri extends Component {
  handleSave = (row) => {
    let params = new FormData();
    params.append("type", "cazuri");
    params.append("row", JSON.stringify(row));
    axios.post(pathString + "admin/update.php", params).then(() => {
      this.props.getData();
    });
  };

  handleDelete = (row) => {
    let params = new FormData();

    params.append("nrCrt", row["nr. crt."]);
    params.append("type", "cazuri");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      this.props.getData();
    });
  };

  render() {
    const user = this.props.user;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    return (
      <Table
        components={components}
        columns={[
          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Descarcă",
                  render: (placeholder, record) => {
                    var searchText;
                    if (record.cnp && record.cnp != "") {
                      searchText = record.cnp;
                    } else
                      searchText = record["nume"] + " " + record["prenume"];
                    if (
                      ((record.cnp && record.cnp != "") ||
                        (record["nume"] && record["prenume"])) &&
                      record.stare &&
                      record.stare.toLowerCase() != "decedat" &&
                      record.stare.toLowerCase() != "vindecat" &&
                      record["data_confirmarii"] &&
                      record["medic familie"]
                    )
                      return (
                        <DownloadComponent
                          record={record}
                          searchText={searchText}
                        />
                      );
                    else return <DownloadErrorComponent />;
                  },
                },
              ]
            : []),
          {
            width: 90,
            title: "Anchetă",
            render: (placeholder, record) => {
              // if (
              //   (record.cnp && record.cnp != "") ||
              //   (record["nume prenume"] && record["nume prenume"])
              // )
              //   return (
              return (
                <CustomAncheta record={record} getData={this.props.getData} />
              );
              //   );
              // else return null;
            },
          },

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr. crt."]
            ? [
                {
                  width: 170,
                  title: "Nr. crt.",
                  dataIndex: "nr. crt.",
                  render(text, record) {
                    return {
                      props: {
                        style: {
                          background: record.stare
                            ? record.stare.toLowerCase() == "carantinat" ||
                              record.stare.toLowerCase() == "izolat" ||
                              record.stare.toLowerCase() == "infirmat"
                              ? "#ffb3b3"
                              : record.stare.toLowerCase() == "decedat"
                              ? "#c0c0c0"
                              : "white"
                            : "white",
                        },
                      },
                      children: <div>{text}</div>,
                    };
                  },
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr. crt."],
                    dataIndex: "nr. crt.",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["status ancheta"]
            ? [
                {
                  width: 140,
                  title: "Status anchetă",
                  dataIndex: "status ancheta",
                  render: (placeholder, record) => (
                    <StatusAnchetaDropdown
                      statusAnchetaVeche={record["status ancheta"]}
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["status ancheta"]
                      }
                      onChange={(statusAncheta) =>
                        this.handleSave({
                          ...record,
                          "status ancheta": statusAncheta,
                        })
                      }
                    />
                  ),
                  filters: [
                    {
                      text: "Nerepartizata",
                      value: "Nerepartizata",
                    },
                    {
                      text: "Repartizata",
                      value: "Repartizata",
                    },

                    {
                      text: "Finalizata",
                      value: "Finalizata",
                    },
                  ],

                  onFilter: (value, record) =>
                    // (value == "Nerepartizata" &&
                    //   (record.statusAncheta == "" ||
                    //     record.statusAncheta == null)) ||
                    record["status ancheta"] &&
                    record["status ancheta"].indexOf(value) === 0,
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["status ancheta"],
                    dataIndex: "status ancheta",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["responsabil ancheta"]
            ? [
                {
                  width: 155,
                  title: "Responsabil anchetă",
                  dataIndex: "responsabil ancheta",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["responsabil ancheta"],
                    dataIndex: "responsabil ancheta",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["stare"]
            ? [
                {
                  width: 130,
                  title: "Stare",
                  dataIndex: "stare",
                  sorter: (a, b) => a["stare"].localeCompare(b["stare"]),
                  render: (placeholder, record) => (
                    <StateDropdown
                      stareVeche={record["stare"]}
                      editable={
                        user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["stare"]
                      }
                      onChange={(stare) =>
                        this.handleSave({ ...record, stare: stare })
                      }
                    />
                  ),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nume"]
            ? [
                {
                  width: 300,
                  title: "Nume",
                  dataIndex: "nume",
                  editable:
                    user.EDIT_RIGHTS &&
                    user.EDIT_RIGHTS["cazuri"]["localitate"],
                  sorter: (a, b) => a["nume"].localeCompare(b["nume"]),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["nume"],
                    dataIndex: "nume",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["prenume"]
            ? [
                {
                  width: 300,
                  title: "Prenume",
                  dataIndex: "prenume",
                  editable:
                    user.EDIT_RIGHTS &&
                    user.EDIT_RIGHTS["cazuri"]["localitate"],
                  sorter: (a, b) => a["prenume"].localeCompare(b["prenume"]),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["prenume"],
                    dataIndex: "prenume",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["debutul_bolii"]
            ? [
                {
                  width: 150,
                  title: "Debutul bolii",
                  dataIndex: "debutul_bolii",
                  sorter: (a, b) =>
                    moment(a["debutul_bolii"]).unix() -
                    moment(b["debutul_bolii"]).unix(),
                  render: (placeholder, record) => (
                    <span>{record["debutul_bolii"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["debutul_bolii"],
                    dataIndex: "debutul_bolii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS["cazuri"]["judet_infectare"]
            ? [
                {
                  width: 200,
                  title: "Județ infectare",
                  dataIndex: "judet_infectare",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["judet_infectare"]
                      )
                    )
                      return <span>{record["judet_infectare"]}</span>;
                    else
                      return (
                        <CustomCountyComponent
                          onChange={(judet) => {
                            this.handleSave({
                              ...record,
                              ["judet_infectare"]: judet,
                              localitate: "",
                            });
                          }}
                          oldValue={record["judet_infectare"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS["cazuri"]["localitate_infectare"]
            ? [
                {
                  width: 200,
                  title: "Localitate infectare",
                  dataIndex: "localitate_infectare",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["localitate_infectare"]
                      )
                    )
                      return <span>{record["localitate_infectare"]}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(localitate) => {
                            this.handleSave({
                              ...record,
                              ["localitate_infectare"]: localitate,
                            });
                          }}
                          oldValue={record["localitate_infectare"]}
                          selectedCounty={record["judet infectare"]}
                        />
                      );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["siruta_localitate_infectare"]
            ? [
                {
                  width: 300,
                  title: "Siruta",
                  dataIndex: "siruta_localitate_infectare",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["siruta_localitate_infectare"],
                    dataIndex: "siruta_localitate_infectare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["cnp"]
            ? [
                {
                  width: 150,
                  title: "CNP",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["cnp"],
                    dataIndex: "cnp",
                    handleSave: this.handleSave,
                  }),
                  render: (placeholder, record) =>
                    record["cnp"] &&
                    record["cnp"].length == 13 &&
                    parseInt(record["cnp"][0]) != 0 &&
                    parseInt(record["cnp"][0]) != 9 &&
                    parseInt(record["cnp"].substring(3, 5)) <= 12 &&
                    parseInt(record["cnp"].substring(5, 7)) <= 31 ? (
                      <Typography>{record["cnp"]}</Typography>
                    ) : (
                      <Typography style={{ backgroundColor: "#ffff76" }}>
                        {record["cnp"]}
                      </Typography>
                    ),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_nastere"]
            ? [
                {
                  width: 100,
                  title: "Data nașterii",
                  dataIndex: "data_nastere",
                  render: (placeholder, record) => (
                    <span>{record["data_nastere"]}</span>
                  ),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["varsta"]
            ? [
                {
                  width: 80,
                  title: "Vârsta",
                  dataIndex: "varsta",
                  sorter: (a, b) => a.varsta && b.varsta && a.varsta - b.varsta,
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_recoltarii"]
            ? [
                {
                  width: 180,
                  title: "Data recoltării",
                  dataIndex: "data_recoltarii",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_recoltarii"]
                      }
                      width={150}
                      initialValue={record["data_recoltarii"]}
                      onChange={(date) =>
                        this.handleSave({ ...record, data_recoltarii: date })
                      }
                    />
                  ),

                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_recoltarii"],
                    dataIndex: "data_recoltarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_confirmarii"]
            ? [
                {
                  width: 180,
                  title: "Data confirmării",
                  dataIndex: "data_confirmarii",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_confirmarii"]
                      }
                      width={150}
                      initialValue={record["data_confirmarii"]}
                      onChange={(date) =>
                        this.handleSave({ ...record, data_confirmarii: date })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_confirmarii"],
                    dataIndex: "data_confirmarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr_confirmat"]
            ? [
                {
                  width: 150,
                  title: "Nr. confirmat",
                  dataIndex: "nr_confirmat",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr_confirmat"],
                    dataIndex: "nr_confirmat",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_internarii"]
            ? [
                {
                  width: 180,
                  title: "Data internării",
                  dataIndex: "data_internarii",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_internarii"]
                      }
                      width={150}
                      initialValue={record["data_internarii"]}
                      onChange={(date) =>
                        this.handleSave({ ...record, data_internarii: date })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_internarii"],
                    dataIndex: "data_internarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_externarii"]
            ? [
                {
                  width: 180,
                  title: "Data externării",
                  dataIndex: "data_externarii",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_externarii"]
                      }
                      width={150}
                      initialValue={record["data_externarii"]}
                      onChange={(date) =>
                        this.handleSave({ ...record, data_externarii: date })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_externarii"],
                    dataIndex: "data_externarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_decedat"]
            ? [
                {
                  width: 180,
                  title: "Data decesului",
                  dataIndex: "data_decedat",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_decedat"]
                      }
                      width={150}
                      initialValue={record["data_decedat"]}
                      onChange={(date) =>
                        this.handleSave({ ...record, data_decedat: date })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_decedat"],
                    dataIndex: "data_decedat",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr_decedat"]
            ? [
                {
                  width: 100,
                  title: "Nr. decedat",
                  dataIndex: "nr_decedat",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr_decedat"],
                    dataIndex: "nr_decedat",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["organ control"]
            ? [
                {
                  width: 200,
                  title: "Organ control",
                  dataIndex: "organ control",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["organ control"],
                    dataIndex: "organ control",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["institutie organ control"]
            ? [
                {
                  width: 200,
                  title: "Instituție organ control",
                  dataIndex: "institutie organ control",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["institutie organ control"],
                    dataIndex: "institutie organ control",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["spital"]
            ? [
                {
                  width: 300,
                  title: "Spital",
                  dataIndex: "spital",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["spital"],
                    dataIndex: "spital",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr_pacient"]
            ? [
                {
                  width: 100,
                  title: "Nr. pacient",
                  dataIndex: "nr_pacient",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr_pacient"],
                    dataIndex: "nr_pacient",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["tratament"]
            ? [
                {
                  width: 155,
                  title: "Tratament",
                  dataIndex: "tratament",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["tratament"],
                    dataIndex: "tratament",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["teste"]
            ? [
                {
                  width: 155,
                  title: "Teste",
                  dataIndex: "teste",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["teste"],
                    dataIndex: "teste",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["laborator"]
            ? [
                {
                  width: 250,
                  title: "Laborator",
                  dataIndex: "laborator",
                  render: (placeholder, record) => (
                    <span>{record["laborator"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["laborator"],
                    dataIndex: "laborator",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["motiv carantinare"]
            ? [
                {
                  width: 200,
                  title: "Motiv carantinare",
                  dataIndex: "motiv carantinare",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["motiv carantinare"],
                    dataIndex: "motiv carantinare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["recomandare dr"]
            ? [
                {
                  width: 200,
                  title: "Recomandare doctor",
                  dataIndex: "recomandare dr",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["recomandare dr"]
                      )
                    )
                      return <span>{record["recomandare dr"]}</span>;
                    else
                      return (
                        <CustomRecomandareMedic
                          onChange={(doctor) => {
                            this.handleSave({
                              ...record,
                              "recomandare dr": doctor,
                            });
                          }}
                          oldValue={record["recomandare dr"]}
                        />
                      );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["simptome clinice"]
            ? [
                {
                  width: 200,
                  title: "Simptome clinice",
                  dataIndex: "simptome clinice",
                  render: (placeholder, record) =>
                    record["simptome clinice"] &&
                    record["simptome clinice"].length > 40 ? (
                      <Tooltip
                        title={record["simptome clinice"]}
                        style={{ fontSize: 13 }}
                      >
                        <Typography style={{ fontSize: 13 }}>
                          {record["simptome clinice"].substring(0, 40) + "..."}
                        </Typography>
                      </Tooltip>
                    ) : record["simptome clinice"] &&
                      record["simptome clinice"].length < 40 ? (
                      <Typography style={{ fontSize: 13 }}>
                        {record["simptome clinice"]}
                      </Typography>
                    ) : (
                      ""
                    ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["simptome clinice"],
                    dataIndex: "simptome clinice",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr test pcr"]
            ? [
                {
                  width: 100,
                  title: "Nr. test pcr",
                  dataIndex: "nr test pcr",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr test pcr"],
                    dataIndex: "nr test pcr",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["tip decizie"]
            ? [
                {
                  width: 100,
                  title: "Tip decizie",
                  dataIndex: "tip decizie",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["tip decizie"],
                    dataIndex: "tip decizie",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["medic familie"]
            ? [
                {
                  width: 300,
                  title: "Medic de familie",
                  dataIndex: "medic familie",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["medic familie"]
                      )
                    )
                      return <span>{record["medic familie"]}</span>;
                    else
                      return (
                        <MedicSelectComponent
                          onChange={(medicFam) => {
                            this.handleSave({
                              ...record,
                              "medic familie": medicFam,
                            });
                          }}
                          oldValue={record["medic familie"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["sex"]
            ? [
                {
                  width: 60,
                  title: "Sex",
                  dataIndex: "sex",
                  filters: [
                    {
                      text: "M",
                      value: "M",
                    },
                    {
                      text: "F",
                      value: "F",
                    },

                    {
                      text: "Necompletat",
                      value: "necompletat",
                    },
                  ],

                  onFilter: (value, record) =>
                    (value == "necompletat" &&
                      (record.sex == "" || record.sex == null)) ||
                    (record.sex && record.sex.indexOf(value) === 0),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["sex"],
                    dataIndex: "sex",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["email"]
            ? [
                {
                  width: 200,
                  title: "Email",
                  dataIndex: "email",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["email"],
                    dataIndex: "email",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr_telefon"]
            ? [
                {
                  width: 130,
                  title: "Nr. telefon",
                  dataIndex: "nr_telefon",

                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr_telefon"],
                    dataIndex: "nr_telefon",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["serie ci"]
            ? [
                {
                  width: 80,
                  title: "Serie CI",
                  dataIndex: "serie ci",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["serie ci"],
                    dataIndex: "serie ci",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["numar ci"]
            ? [
                {
                  width: 200,
                  title: "Număr CI",
                  dataIndex: "numar ci",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["numar ci"],
                    dataIndex: "numar ci",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["ci eliberat"]
            ? [
                {
                  width: 200,
                  title: "CI eliberat",
                  dataIndex: "ci eliberat",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["ci eliberat"],
                    dataIndex: "ci eliberat",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["data eliberare ci"]
            ? [
                {
                  width: 180,
                  title: "Data eliberare CI",
                  dataIndex: "data eliberare ci",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data eliberare ci"]
                      }
                      width={150}
                      initialValue={record["data eliberare ci"]}
                      onChange={(date) =>
                        this.handleSave({
                          ...record,
                          "data eliberare ci": date,
                        })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data eliberare ci"],
                    dataIndex: "data eliberare ci",
                    handleSave: this.handleSave,
                  }),
                  // render: (placeholder, record) => (
                  //   <span>{record["data eliberare ci"]}</span>
                  // ),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["cetatenie"]
            ? [
                {
                  width: 130,
                  title: "Cetățenie",
                  dataIndex: "cetatenie",
                  sorter: (a, b) =>
                    a["cetatenie"].localeCompare(b["cetatenie"]),
                  render: (placeholder, record) => (
                    <DropdownNationalitate
                      cetatenieVeche={record["cetatenie"]}
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["cetatenie"]
                      }
                      onChange={(cetatenie) =>
                        this.handleSave({
                          ...record,
                          cetatenie: cetatenie,
                        })
                      }
                    />
                  ),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["judet_domiciliu"]
            ? [
                {
                  width: 200,
                  title: "Județ domiciliu",
                  dataIndex: "judet_domiciliu",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["judet_domiciliu"]
                      )
                    )
                      return <span>{record["judet_domiciliu"]}</span>;
                    else
                      return (
                        <CustomCountyComponent
                          onChange={(judet) => {
                            this.handleSave({
                              ...record,
                              ["judet_domiciliu"]: judet,
                              localitate: "",
                            });
                          }}
                          oldValue={record["judet_domiciliu"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["adresa"]
            ? [
                {
                  width: 130,
                  title: "Adresa domiciliu",
                  dataIndex: "adresa",

                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["cazuri"]["adresa"],
                    dataIndex: "adresa",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["adresa_localitate"]
            ? [
                {
                  width: 200,
                  title: "Localitate domiciliu",
                  dataIndex: "adresa_localitate",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["adresa_localitate"]
                      )
                    )
                      return <span>{record["adresa_localitate"]}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(localitate) => {
                            this.handleSave({
                              ...record,
                              ["adresa_localitate"]: localitate,
                            });
                          }}
                          oldValue={record["adresa_localitate"]}
                          selectedCounty={record["judet adresa buletin"]}
                        />
                      );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["adresa_nr"]
            ? [
                {
                  width: 100,
                  title: "Nr.",
                  dataIndex: "adresa_nr",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["adresa_nr"],
                    dataIndex: "adresa_nr",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["adresa_detalii"]
            ? [
                {
                  width: 300,
                  title: "Detalii",
                  dataIndex: "adresa_detalii",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["adresa_detalii"],
                    dataIndex: "adresa_detalii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["ocupatie"]
            ? [
                {
                  width: 130,
                  title: "Ocupație",
                  dataIndex: "ocupatie",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["ocupatie"],
                    dataIndex: "ocupatie",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["Loc de munca"]
            ? [
                {
                  width: 155,
                  title: "Loc de muncă",
                  dataIndex: "Loc de munca",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["Loc de munca"],
                    dataIndex: "Loc de munca",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["judet adresa reala"]
            ? [
                {
                  width: 200,
                  title: "Județ (adresa reală)",
                  dataIndex: "judet adresa reala",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["judet adresa reala"]
                      )
                    )
                      return <span>{record["judet adresa reala"]}</span>;
                    else
                      return (
                        <CustomCountyComponent
                          onChange={(judet) => {
                            this.handleSave({
                              ...record,
                              ["judet adresa reala"]: judet,
                              localitate: "",
                            });
                          }}
                          oldValue={record["judet adresa reala"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["localitate adresa reala"]
            ? [
                {
                  width: 200,
                  title: "Localitate (adresa reală)",
                  dataIndex: "localitate adresa reala",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["localitate adresa reala"]
                      )
                    )
                      return <span>{record["localitate adresa reala"]}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(localitate) => {
                            this.handleSave({
                              ...record,
                              ["localitate adresa reala"]: localitate,
                            });
                          }}
                          oldValue={record["localitate adresa reala"]}
                          selectedCounty={record["judet adresa reala"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["strada adresa reala"]
            ? [
                {
                  width: 300,
                  title: "Strada (adresa reală)",
                  dataIndex: "strada adresa reala",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["strada adresa reala"],
                    dataIndex: "strada adresa reala",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["nr adresa reala"]
            ? [
                {
                  width: 150,
                  title: "Nr. (adresa reală)",
                  dataIndex: "nr adresa reala",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr adresa reala"],
                    dataIndex: "nr adresa reala",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["detalii adresa reala"]
            ? [
                {
                  width: 300,
                  title: "Detalii (adresa reală)",
                  dataIndex: "detalii adresa reala",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["detalii adresa reala"],
                    dataIndex: "detalii adresa reala",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["judet adresa izolare"]
            ? [
                {
                  width: 200,
                  title: "Județ (adresa izolare)",
                  dataIndex: "judet adresa izolare",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["judet adresa izolare"]
                      )
                    )
                      return <span>{record["judet adresa izolare"]}</span>;
                    else
                      return (
                        <CustomCountyComponent
                          onChange={(judet) => {
                            this.handleSave({
                              ...record,
                              ["judet adresa izolare"]: judet,
                              localitate: "",
                            });
                          }}
                          oldValue={record["judet adresa izolare"]}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["localitate adresa izolare"]
            ? [
                {
                  width: 200,
                  title: "Localitate (adresa izolare)",
                  dataIndex: "localitate adresa izolare",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["localitate adresa izolare"]
                      )
                    )
                      return <span>{record["localitate adresa izolare"]}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(localitate) => {
                            this.handleSave({
                              ...record,
                              ["localitate adresa izolare"]: localitate,
                            });
                          }}
                          oldValue={record["localitate adresa izolare"]}
                          selectedCounty={record["judet adresa izolare"]}
                        />
                      );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["strada adresa izolare"]
            ? [
                {
                  width: 300,
                  title: "Strada (adresa izolare)",
                  dataIndex: "strada adresa izolare",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["strada adresa izolare"],
                    dataIndex: "strada adresa izolare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["nr adresa izolare"]
            ? [
                {
                  width: 150,
                  title: "Nr. (adresa izolare)",
                  dataIndex: "nr adresa izolare",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["nr adresa izolare"],
                    dataIndex: "nr adresa izolare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["detalii adresa izolare"]
            ? [
                {
                  width: 300,
                  title: "Detalii (adresa izolare)",
                  dataIndex: "detalii adresa izolare",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["detalii adresa izolare"],
                    dataIndex: "detalii adresa izolare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["cazuri"]["link_epidemiologic"]
            ? [
                {
                  width: 150,
                  title: "Link epidemiologic",
                  dataIndex: "link_epidemiologic",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["link_epidemiologic"],
                    dataIndex: "link_epidemiologic",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["comentarii"]
            ? [
                {
                  width: 150,
                  title: "Comentarii",
                  dataIndex: "comentarii",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["comentarii"],
                    dataIndex: "comentarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["data_creare"]
            ? [
                {
                  width: 180,
                  title: "Data creare",
                  dataIndex: "data_creare",
                  // render: (placeholder, record) => (
                  //   <span>{record["data_creare"]}</span>
                  // ),
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["data_creare"]
                      }
                      width={150}
                      initialValue={record["data_creare"]}
                      onChange={(date) =>
                        this.handleSave({
                          ...record,
                          data_creare: date,
                        })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["data_creare"],
                    dataIndex: "data_creare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["cazuri"]["Data import"]
            ? [
                {
                  width: 180,
                  title: "Data import",
                  dataIndex: "Data import",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["cazuri"]["Data import"]
                      }
                      width={150}
                      initialValue={record["Data import"]}
                      onChange={(date) =>
                        this.handleSave({
                          ...record,
                          "Data import": date,
                        })
                      }
                    />
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["cazuri"]["Data import"],
                    dataIndex: "Data import",
                    handleSave: this.handleSave,
                  }),
                  // render: (placeholder, record) => (
                  //   <span>{record["Data import"]}</span>
                  // ),
                },
              ]
            : []),

          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Șterge",
                  render: (placeholder, record) => {
                    return (
                      <CustomDeleteComponent
                        handleDelete={() => this.handleDelete(record)}
                      />
                    );
                  },
                },
              ]
            : []),

          ,
        ]}
        bordered
        rowKey={(record) => record["nr. crt."]}
        loading={this.props.loading}
        dataSource={this.props.tableData}
        pagination={false}
        size="small"
        scroll={{ y: "calc(100vh - 180px)" }}
      />
    );
  }
}
