import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import pathString from "./../../../get_php_link";
import ExportIcon from "@material-ui/icons/ArrowDownward";
import mystyle from "./../mystyle.module.css";
import ModalSelectExport from "./ModalSelectExport";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectExportType() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const exportCazuri = () => {
  //   window.open(pathString + "admin/exportCazuri.php");
  // };
  // const exportContacti = () => {
  //   window.open(pathString + "admin/exportContacti.php");
  // };

  const handleItemClick = (exportFunction) => {
    exportFunction();
    handleClose();
  };

  return (
    <div>
      <ModalSelectExport
        // // getData={getData}
        isModalVisible={isModalVisible}
        hideModal={hideModal}
      />
      <button
        className={mystyle.exportButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => {
          showModal();
        }}
        // onMouseEnter={handleClick}
      >
        <ExportIcon />
        <span style={{ marginLeft: 2 }}>Exportă fișier excel</span>
      </button>
      {/* <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: 47, marginLeft: 10, width: 300, maxWidth: 300 }}
      >
        <MenuItem
          style={{ width: 200 }}
          onClick={() => {
            handleItemClick(exportCazuri);
          }}
        >
          Export cazuri
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleItemClick(exportContacti);
          }}
        >
          Export contacți
        </MenuItem>
      </Menu> */}
    </div>
  );
}
