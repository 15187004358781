import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { UserContext } from "./../../Context";

const typesObj = {
  0: "Medic familie",
  1: "Contribuitor",
  2: "Admin",
  3: "Moderator",
  "-1": "Dezactivat",
};

export default function DoctorTypeSelect(props) {
  const { onChange, oldType } = props;
  const [type, setType] = useState(oldType);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, setUser } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    setType(selectedType);
    onChange(selectedType);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {typesObj[type]}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleTypeChange(0);
            handleClose();
          }}
        >
          Medic familie
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange(1);
            handleClose();
          }}
        >
          Contribuitor
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange(3);
            handleClose();
          }}
        >
          Moderator
        </MenuItem>
        {user.ACCESS_LEVEL == 2 && (
          <MenuItem
            onClick={() => {
              handleTypeChange(2);
              handleClose();
            }}
          >
            Admin
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleTypeChange(-1);
            handleClose();
          }}
        >
          Dezactivat
        </MenuItem>
      </Menu>
    </div>
  );
}
