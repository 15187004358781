import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    minWidth: 150,
  },
});

export default function TabelErori(props) {
  const classes = useStyles();
  const { data } = props;

  const numeFisier = data ? (data.length != 0 ? data[0].nume_fisier : "") : "";
  const dataImport = data ? (data.length != 0 ? data[0].data : "") : "";
  return (
    <div>
      <div
        style={{
          display: "flex",
          paddingLeft: 10,
          paddingRight: 10,
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: 18 }}>Numele fișierului: {numeFisier}</span>
        <span style={{ fontSize: 18 }}>Data importului: {dataImport}</span>
      </div>
      <TableContainer
        component={Paper}
        style={{ width: "100%", marginTop: 5 }}
        id="tableBody"
      >
        <Table
          stickyHeader
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 70, width: 70 }}>
                Linie fișier
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                Eroare
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={row.CNP + index}>
                  <TableCell component="th" scope="row">
                    {row.linie_fisier}
                  </TableCell>

                  <TableCell align="left">{row.eroare}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
