import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import pathString from "./../../../get_php_link";

export default function DownloadComponent(props) {
  const { searchText } = props;
  const handleClick = () => {
    window.open(pathString + "helpers/export.php?search=" + searchText);
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleClick}>
        <DownloadIcon style={{ color: "#34AD8B" }} />
      </IconButton>
    </div>
  );
}
