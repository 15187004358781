import React, { useEffect, useContext, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Modal, Backdrop } from "@material-ui/core";
import mystyle from "./admin/mystyle.module.css";
import { UserContext } from "./Context";
import axios from "axios";
import pathString from "../get_php_link";
import Switch from "@material-ui/core/Switch";
import columnMapping from "./utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "90vw",
    height: "80vh",
  },
  details: {
    padding: 20,
    display: "flex",
    overflow: "auto",
    height: "70vh",
  },
  typeContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  typeColumn: {
    flex: 1,
    display: "flex",
  },
}));

const tableTypes = {
  cazuri: "Cazuri COVID",
  contacti: "Contacti",
  suspecti: "Suspecti",
  medici: "Cadre medicale",
};

export default function ModalCustomizare(props) {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);

  const [data, setData] = useState(null);

  const getRights = () => {
    if (user && user.USER_UID) {
      let params = new FormData();
      params.append("id", user.USER_UID);
      axios
        .post(pathString + "admin/getUserColumns.php", params)
        .then((response) => {
          setData(JSON.parse(response.data));
        });
    }
  };
  useEffect(getRights, [user]);

  const save = () => {
    let params = new FormData();
    params.append("id", user.USER_UID);
    params.append("data", JSON.stringify(data));
    axios
      .post(pathString + "admin/saveUserColumns.php", params)
      .then((response) => {
        getRights();
      });
    props.hideModal();
    setUser((user) => ({ ...user, VIEW_RIGHTS: data }));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className={mystyle.headerModal}
        >
          <div style={{ flex: 1 }}></div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 id="transition-modal-title" style={{ color: "white" }}>
              Customizare
            </h3>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <button
              style={{ fontSize: 15 }}
              className={mystyle.logoutButton}
              onClick={save}
            >
              Salvați
            </button>
          </div>
        </div>
        <div className={classes.details}>
          {
            data &&
              Object.keys(data).map((tipTabela, index) => {
                return (
                  <div
                    key={index + "" + tipTabela}
                    className={classes.typeContainer}
                  >
                    <span style={{ fontSize: 24, paddingLeft: 20 }}>
                      {tableTypes[tipTabela]}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {Object.keys(data[tipTabela]).map(
                        (column, columnIndex) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                              key={column + columnIndex}
                            >
                              {/* <span>{column}</span> */}
                              <span>{columnMapping[column]}</span>
                              <Switch
                                defaultChecked={data[tipTabela][column]}
                                onChange={(e) => {
                                  data[tipTabela][column] = !data[tipTabela][
                                    column
                                  ];
                                  setData(JSON.parse(JSON.stringify(data)));
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })
            // data.map((record, index) => {
            //   return (
            //     <div
            //       className={classes.typeContainer}
            //       key={index + "" + record.TYPE}
            //     >
            //       <div className={classes.typeColumn}>
            //         {Object.keys(record.RIGHTS).map((key, rightsIndex) => {
            //           return (
            //             <div
            //               key={key + rightsIndex}
            //               style={{ display: "flex", flexDirection: "column" }}
            //             >
            //               <span style={{ fontSize: 20 }}>
            //                 {tableTypes[key]}
            //               </span>
            //               {Object.keys(record.RIGHTS[key]).map(
            //                 (column, columnIndex) => {
            //                   return (
            //                     <div
            //                       style={{
            //                         display: "flex",
            //                         alignItems: "center",
            //                         justifyContent: "space-between",
            //                       }}
            //                       key={column + columnIndex}
            //                     >
            //                       <span>{columnMapping[column]}</span>{" "}
            //                       <Switch
            //                         defaultChecked={record.RIGHTS[key][column]}
            //                         onChange={(e) => {
            //                           record.RIGHTS[key][column] = !record
            //                             .RIGHTS[key][column];
            //                           setData(JSON.parse(JSON.stringify(data)));
            //                         }}
            //                       />
            //                     </div>
            //                   );
            //                 }
            //               )}
            //             </div>
            //           );
            //         })}
            //       </div>
            //     </div>
            //   );
            // })}
          }
        </div>
      </div>
    </Modal>
  );
}
