import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  Snackbar,
} from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import mystyle from "./styleModalAncheta.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateSelect from "./../CommonComponents/DateSelect";
import axios from "axios";
import pathString from "./../../../get_php_link";
import TabelAncheta from "./TabelAncheta";
import AddIcon from "@material-ui/icons/Add";
import Cancel from "@material-ui/icons/Cancel";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import { UserContext } from "./../../Context";

import { Typography } from "@material-ui/core/";
import CustomRecomandareMedic from "./../CommonComponents/CustomRecomandareMedic";
import StateDropdown from "./../CommonComponents/StateDropdown";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "95vw",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
  },
  details: {
    padding: 5,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export default function ModalAncheta(props) {
  const selectedCase = props.selectedCase;
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [nrCrt, setNrCrt] = React.useState(selectedCase["nr. crt."]);

  const [nrPacient, setNrPacient] = React.useState(selectedCase["nr_pacient"]);
  const [nrCaz, setNrCaz] = React.useState(selectedCase["numar caz"]);
  const [tipDecizie, setTipDecizie] = React.useState(
    selectedCase["tip decizie"]
  );
  const [nume, setNume] = React.useState(selectedCase["nume"]);
  const [prenume, setPrenume] = React.useState(selectedCase["prenume"]);
  const [stare, setStare] = React.useState(selectedCase["stare"]);
  const [CNP, setCNP] = React.useState(selectedCase["cnp"]);
  const [sex, setsex] = React.useState(selectedCase["sex"]);
  const [dataDebut, setDataDebut] = React.useState(
    selectedCase["debutul_bolii"]
  );
  const [dataConfirmare, setDataConfirmare] = React.useState(
    selectedCase["data_confirmarii"]
  );
  const [dataRecoltare, setDataRecoltare] = React.useState(
    selectedCase["data_recoltarii"]
  );
  const [dataInternare, setDataInternare] = React.useState(
    selectedCase["data_internarii"]
  );
  const [dataExternare, setDataExternare] = React.useState(
    selectedCase["data_externarii"]
  );
  const [dataDeces, setDataDeces] = React.useState(
    selectedCase["data_decedat"]
  );
  const [nrDecedat, setNrDecedat] = React.useState(selectedCase["nr_decedat"]);
  const [telefon, setTelefon] = React.useState(selectedCase["nr_telefon"]);
  const [email, setEmail] = React.useState(selectedCase["email"]);
  const [serieCi, setSerieCi] = React.useState(selectedCase["serie ci"]);
  const [numarCi, setNumarCi] = React.useState(selectedCase["numar ci"]);
  const [ciEliberat, setCiEliberat] = React.useState(
    selectedCase["ci eliberat"]
  );
  const [dataEliberareCi, setDataEliberareCi] = React.useState(
    selectedCase["data eliberare ci"]
  );
  const [cetatenie, setCetatenie] = React.useState(selectedCase["cetatenie"]);
  const [nrConfirmat, setNrConfirmat] = React.useState(
    selectedCase["nr_confirmat"]
  );
  const [judetInfectare, setJudetInfectare] = React.useState(
    selectedCase["judet_infectare"]
  );
  const [siruta, setSiruta] = React.useState(
    selectedCase["siruta_localitate_infectare"]
  );
  const [localitateInfectare, setLocalitateInfectare] = React.useState(
    selectedCase["localitate_infectare"]
  );
  const [spital, setSpital] = React.useState(selectedCase["spital"]);
  const [tratament, setTratament] = React.useState(selectedCase["tratament"]);
  const [linkEpidemiologic, setLinkEpidemiologic] = React.useState(
    selectedCase["link_epidemiologic"]
  );
  const [laborator, setLaborator] = React.useState(selectedCase["laborator"]);
  const [nrTestPcr, setNrTestPcr] = React.useState(selectedCase["nr test pcr"]);
  const [recomandareDr, setRecomandareDr] = React.useState(
    selectedCase["recomandare dr"]
  );
  const [medic, setMedic] = React.useState(selectedCase["medic familie"]);
  const [motivCarantinare, setMotivCarantinare] = React.useState(
    selectedCase["motiv carantinare"]
  );

  const [simptomeClinice, setSimptomeClinice] = React.useState(
    selectedCase["simptome clinice"]
      ? selectedCase["simptome clinice"].split(",")
      : []
  );
  const [teste, setTeste] = React.useState(selectedCase["teste"]);
  const [comentarii, setComentarii] = React.useState(
    selectedCase["comentarii"]
  );
  const [ocupatie, setOcupatie] = React.useState(selectedCase["ocupatie"]);
  const [locDeMunca, setLocDeMunca] = React.useState(
    selectedCase["Loc de munca"]
  );
  const [judetDomiciliu, setJudetDomiciliu] = React.useState(
    selectedCase["judet_domiciliu"]
  );
  const [localitateDomiciliu, setLocalitateDomiciliu] = React.useState(
    selectedCase["adresa_localitate"]
  );

  const [adresa, setAdresa] = React.useState(selectedCase["adresa"]);

  const [adresaStrada, setAdresaStrada] = React.useState(
    selectedCase["adresa_strada"]
  );
  const [adresaNr, setAdresaNr] = React.useState(selectedCase["adresa_nr"]);
  const [adresaDetalii, setAdresaDetalii] = React.useState(
    selectedCase["adresa_detalii"]
  );
  const [judetAdresaReala, setJudetAdresaReala] = React.useState(
    selectedCase["judet adresa reala"]
  );
  const [localitateAdresaReala, setLocalitateAdresaReala] = React.useState(
    selectedCase["localitate adresa reala"]
  );
  const [stradaAdresaReala, setStradaAdresaReala] = React.useState(
    selectedCase["strada adresa reala"]
  );
  const [nrAdresaReala, setNrAdresaReala] = React.useState(
    selectedCase["nr adresa reala"]
  );
  const [detaliiAdresaReala, setDetaliiAdresaReala] = React.useState(
    selectedCase["detalii adresa reala"]
  );
  const [judetAdresaIzolare, setJudetAdresaIzolare] = React.useState(
    selectedCase["judet adresa izolare"]
  );
  const [localitateAdresaIzolare, setLocalitateAdresaIzolare] = React.useState(
    selectedCase["localitate adresa izolare"]
  );
  const [stradaAdresaIzolare, setStradaAdresaIzolare] = React.useState(
    selectedCase["strada adresa izolare"]
  );
  const [nrAdresaIzolare, setNrAdresaIzolare] = React.useState(
    selectedCase["nr adresa izolare"]
  );
  const [detaliiAdresaIzolare, setDetaliiAdresaIzolare] = React.useState(
    selectedCase["detalii adresa izolare"]
  );
  const [organControl, setOrganControl] = React.useState(
    selectedCase["organ control"]
  );
  const [institutieControl, setInstitutieControl] = React.useState(
    selectedCase["institutie organ control"]
  );

  const [statusAncheta, setStatusAncheta] = React.useState(
    selectedCase["status ancheta"]
  );
  const [responsabilAncheta, setResponsabilAncheta] = React.useState(
    selectedCase["responsabil ancheta"]
  );

  const [fullTableData, setFullTableData] = useState([]);

  const [addRowClicked, setAddRowClicked] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [snackbarText, setsnackbarText] = React.useState("");
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getData = () => {
    let parameter = new FormData();

    parameter.append("NR_CAZ_SURSA", selectedCase["nr. crt."]);

    axios
      .post(pathString + "admin/getContacti.php", parameter)
      .then((response) => {
        let returner = response.data;
        setFullTableData(returner);
      })
      .catch(() => {});
  };

  useEffect(getData, []);

  const handleAddClick = () => {
    setAddRowClicked((lastVal) => {
      return !lastVal;
    });
  };

  const formatDate = (strDate) => {
    return strDate; // if (strDate) {
    //   let arr = strDate.split(".");
    //   if (arr.length == 3) return arr[2] + "-" + arr[1] + "-" + arr[0];
    //   return strDate;
    // }
  };

  const save = (statusAncheta) => {
    if (addRowClicked) {
      handleSnackbarOpen();
      setsnackbarText("Salvați contacții adăugați!");
      return;
    }
    var today = new Date();
    // var dd = String(today.getDate()).padStart(2, "0");
    // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // var yyyy = today.getFullYear();
    // today = yyyy + "-" + mm + "-" + dd;
    if (
      (dataDeces && new Date(dataDeces).getTime() > today.getTime()) ||
      (dataExternare && new Date(dataExternare).getTime() > today.getTime()) ||
      (dataInternare && new Date(dataInternare).getTime() > today.getTime())
    ) {
      handleSnackbarOpen();
      setsnackbarText(
        "Data de deces/externare/internare/rezultat/test PCR este mai mare decât cea de azi!"
      );
    } else if (
      dataExternare &&
      dataInternare &&
      new Date(dataExternare).getTime() < new Date(dataInternare).getTime()
    ) {
      handleSnackbarOpen();
      setsnackbarText("Data de externare este mai mica decat cea de internare");
    } else {
      let params = new FormData();
      params.append("NR_CRT", nrCrt);
      params.append("NR_PACIENT", nrPacient);
      params.append("NR_CAZ", nrCaz);
      params.append("TIP_DECIZIE", tipDecizie);
      params.append("NUME", nume);
      params.append("PRENUME", prenume);
      params.append("STARE", stare);
      params.append("CNP", CNP);
      params.append("SEX", sex);
      params.append("DATA_DEBUT", dataDebut);
      params.append("DATA_CONFIRMARE", dataConfirmare);
      params.append("DATA_RECOLTARE", dataRecoltare);
      params.append("DATA_INTERNARII", dataInternare);
      params.append("DATA_EXTERNARII", dataExternare);
      params.append("DATA_DECES", dataDeces);
      params.append("NR_DECEDAT", nrDecedat);
      params.append("TELEFON", telefon);
      params.append("EMAIL", email);
      params.append("SERIE_CI", serieCi);
      params.append("NUMAR_CI", numarCi);
      params.append("CI_ELIBERAT", ciEliberat);
      params.append("DATA_ELIBERARE_CI", dataEliberareCi);
      params.append("CETATENIE", cetatenie);
      params.append("NR_CONFIRMAT", nrConfirmat);
      params.append("JUDET_INFECTARE", judetInfectare);
      params.append("SIRUTA", siruta);
      params.append("LOCALITATE_INFECTARE", localitateInfectare);
      params.append("SPITAL", spital);
      params.append("TRATAMENT", tratament);
      params.append("LINK", linkEpidemiologic);
      params.append("LABORATOR", laborator);
      params.append("NR_TEST_PCR", nrTestPcr);
      params.append("RECOMANDARE_DOCTOR", recomandareDr);
      params.append("MEDIC", medic);
      params.append("MOTIV_CARANTINARE", motivCarantinare);
      params.append("SIMPTOME_CLINICE", simptomeClinice);
      params.append("TESTE", teste);
      params.append("COMENTARII", comentarii);
      params.append("OCUPATIE", ocupatie);
      params.append("LOC_DE_MUNCA", locDeMunca);
      params.append("JUDET_DOMICILIU", judetDomiciliu);
      params.append("LOCALITATE_DOMICILIU", localitateDomiciliu);
      params.append("ADRESA", adresa);
      params.append("LOCALITATE_DOMICILIU", localitateDomiciliu);
      params.append("STRADA_DOMICILIU", adresaStrada);
      params.append("NR_ADRESA", adresaNr);
      params.append("DETALII_ADRESA", adresaDetalii);
      params.append("JUDET_ADRESA_REALA", judetAdresaReala);
      params.append("LOCALITATE_ADRESA_REALA", localitateAdresaReala);
      params.append("STRADA_ADRESA_REALA", stradaAdresaReala);
      params.append("NR_ADRESA_REALA", nrAdresaReala);
      params.append("DETALII_ADRESA_REALA", detaliiAdresaReala);
      params.append("JUDET_ADRESA_IZOLARE", judetAdresaIzolare);
      params.append("LOCALITATE_ADRESA_IZOLARE", localitateAdresaIzolare);
      params.append("STRADA_ADRESA_IZOLARE", stradaAdresaIzolare);
      params.append("NR_ADRESA_IZOLARE", nrAdresaIzolare);
      params.append("DETALII_ADRESA_IZOLARE", detaliiAdresaIzolare);
      params.append("ORGAN_CONTROL", organControl);
      params.append("INSTITUTIE_CONTROL", institutieControl);
      params.append("STATUS_ANCHETA", statusAncheta);
      params.append("RESPONSABIL_ANCHETA", user.name);
      params.append("STATUS_ANCHETA", statusAncheta);
      axios.post(pathString + "admin/updateAncheta.php", params).then(() => {
        props.getData();
        props.hideModal();
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Anchetă - {selectedCase["nume"]} {selectedCase["prenume"]} -{" "}
          {selectedCase["nr. crt."]}
        </h2>
        <div className={classes.details}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                width: 450,
                height: 50,
                textAlign: "center",
                borderRadius: 10,
                color: "white",
                marginBottom: 30,
              }}
            >
              {snackbarText}
            </div>
          </Snackbar>

          <div className={mystyle.paper}>
            <div className={mystyle.firstColumn}>
              {/* <div className={mystyle.label}>
                <label alignItems="left" style={{ fontSize: 12 }}>
                  Nr. crt.:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    value={nrCrt}
                    alignItems="right"
                    className={mystyle.textField}
                  />
                </div>
              </div> */}

              <div className={mystyle.label}>
                <label alignItems="left" style={{ fontSize: 12 }}>
                  Nr. pacient:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    className={mystyle.textField}
                    value={nrPacient}
                    onChange={(event) => {
                      setNrPacient(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Tip decizie:
                <div className={mystyle.value}>
                  <TextField
                    value={tipDecizie}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setTipDecizie(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left" style={{ fontSize: 12 }}>
                  Nume*:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    value={nume}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setNume(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left" style={{ fontSize: 12 }}>
                  Prenume*:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    value={prenume}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setPrenume(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Stare:
                <div className={mystyle.value}>
                  <StateDropdown
                    stareVeche={selectedCase["stare"]} //"Carantinat"
                    onChange={(stare) => setStare(stare)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                CNP:
                <div className={mystyle.value}>
                  <TextField
                    value={CNP}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setCNP(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Sex:
                <div className={mystyle.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={sex == "M"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setsex("M");
                          } else {
                            setsex("");
                          }
                        }}
                      />
                    }
                    label={<Typography style={{ fontSize: 12 }}>M</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={sex == "F"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setsex("F");
                          } else {
                            setsex("");
                          }
                        }}
                      />
                    }
                    label={<Typography style={{ fontSize: 12 }}>F</Typography>}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Data confirmării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataConfirmare(date)}
                    initialValue={formatDate(dataConfirmare)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Data recoltării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataRecoltare(date)}
                    initialValue={formatDate(dataRecoltare)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Data internării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataInternare(date)}
                    initialValue={formatDate(dataInternare)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Data externării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataExternare(date)}
                    initialValue={formatDate(dataExternare)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Data decesului:{" "}
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataDeces(date)}
                    initialValue={formatDate(dataDeces)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Nr. decedat:
                <div className={mystyle.value}>
                  <TextField
                    value={nrDecedat}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setNrDecedat(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Telefon:
                <div className={mystyle.value}>
                  <TextField
                    value={telefon}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setTelefon(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Email:
                <div className={mystyle.value}>
                  <TextField
                    value={email}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Serie CI:
                <div className={mystyle.value}>
                  <TextField
                    value={serieCi}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      let text = event.target.value;
                      if (text.length <= 2 && !isNumeric(text))
                        setSerieCi(text);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Numar CI:
                <div className={mystyle.value}>
                  <TextField
                    value={numarCi}
                    alignItems="right"
                    className={mystyle.textField}
                    value={numarCi}
                    onChange={(event) => {
                      let text = event.target.value;
                      if (text.length <= 6 && isNumeric(text))
                        setNumarCi(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                CI eliberat:
                <div className={mystyle.value}>
                  <TextField
                    value={ciEliberat}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setCiEliberat(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.labelDate}>
                Data eliberare CI:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataEliberareCi(date)}
                    initialValue={formatDate(dataEliberareCi)}
                  />
                </div>
              </div>

              <div className={mystyle.labelDate}>
                Cetățenie:{" "}
                <div className={mystyle.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={cetatenie == "Romana"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setCetatenie("Romana");
                          } else {
                            setCetatenie("");
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>romana</Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        checked={cetatenie == "Alta"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setCetatenie("Alta");
                          } else {
                            setCetatenie("");
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>alta</Typography>
                    }
                  />
                </div>
              </div>
            </div>
            <div className={mystyle.secondColumn}>
              <div className={mystyle.label}>
                Nr. confirmat:
                <div className={mystyle.value}>
                  <TextField
                    value={nrConfirmat}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setNrConfirmat(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Județ infectare:
                <div className={mystyle.value}>
                  <div className={mystyle.textField}>
                    {" "}
                    <CustomCountyComponent
                      onChange={(judet) => {
                        setJudetInfectare(judet);
                        setLocalitateInfectare("");
                      }}
                      oldValue={judetInfectare}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Siruta localitate infectare:
                <div className={mystyle.value}>
                  <TextField
                    value={siruta}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setSiruta(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Localitate infectare:
                <div className={mystyle.value}>
                  <div className={mystyle.textField}>
                    <CustomCityComponent
                      onChange={(localitate) => {
                        setLocalitateInfectare(localitate);
                      }}
                      oldValue={localitateInfectare}
                      selectedCounty={judetInfectare}
                    />
                  </div>
                </div>
              </div>
              <div className={mystyle.label}>
                Spital:
                <div className={mystyle.value}>
                  <TextField
                    value={spital}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setSpital(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Tratament:
                <div className={mystyle.value}>
                  <TextField
                    value={tratament}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setTratament(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Link epidemiologic:
                <div className={mystyle.value}>
                  <TextField
                    value={linkEpidemiologic}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setLinkEpidemiologic(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Laborator:
                <div className={mystyle.value}>
                  <TextField
                    value={laborator}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setLaborator(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Nr. test pcr:{" "}
                <div className={mystyle.value}>
                  <TextField
                    value={nrTestPcr}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setNrTestPcr(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Recomandare doctor:
                <div className={mystyle.value}>
                  <div className={mystyle.textField}>
                    <CustomRecomandareMedic
                      onChange={(doctor) => {
                        setRecomandareDr(doctor);
                      }}
                      oldValue={recomandareDr}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Medic de familie:{" "}
                <div className={mystyle.value}>
                  <div className={mystyle.textField}>
                    <MedicSelectComponent
                      onChange={(medicFam) => {
                        setMedic(medicFam);
                      }}
                      oldValue={medic}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Motiv carantinare:
                <div className={mystyle.value}>
                  <TextField
                    value={motivCarantinare}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setMotivCarantinare(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Teste:
                <div className={mystyle.value}>
                  <TextField
                    value={teste}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setTeste(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>
                  <div style={{ fontWeight: "bold" }}>Simptome clinice</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("febra")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "febra",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "febra")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            febra
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("mialgii")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "mialgii",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "mialgii")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            milagii
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("diaree")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "diaree",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "diaree")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            diaree
                          </Typography>
                        }
                      />
                    </div>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("cefalee")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "cefalee",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "cefalee")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            cefalee
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("astenie")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "astenie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "astenie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            astenie
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("pleurezie")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "pleurezie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "pleurezie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            pleurezie
                          </Typography>
                        }
                      />
                    </div>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("tuse")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "tuse",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "tuse")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>tuse</Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("coriza")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "coriza",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "coriza")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            coriza
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("pneumonie")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "pneumonie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "pneumonie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            pneumonie
                          </Typography>
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 2,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("dureri in gat")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "dureri in gat",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "dureri in gat")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            dureri in gat
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes("dispnee")}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "dispnee",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "dispnee")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            dispnee
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            checked={simptomeClinice.includes(
                              "bronhopneumonie"
                            )}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "bronhopneumonie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter(
                                    (e) => e != "bronhopneumonie"
                                  )
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            bronhopneumonie
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mystyle.simptomeColoana}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          checked={simptomeClinice.includes(
                            "status mental alterat"
                          )}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "status mental alterat",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "status mental alterat"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          status mental alterat
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          checked={simptomeClinice.includes(
                            "dificultate in respiratie"
                          )}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "dificultate in respiratie",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "dificultate in respiratie"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          dificultate in respiratie
                        </Typography>
                      }
                    />
                  </div>
                  <div className={mystyle.simptomeColoana}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          checked={simptomeClinice.includes(
                            "anorexie/greturi/varsaturi"
                          )}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "anorexie/greturi/varsaturi",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "anorexie/greturi/varsaturi"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          anorexie/greturi/varsaturi
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          checked={simptomeClinice.includes(
                            "infectie respiratorie acuta severa (SARI)"
                          )}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "infectie respiratorie acuta severa (SARI)",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) =>
                                    e !=
                                    "infectie respiratorie acuta severa (SARI)"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          infectie respiratorie acuta severa (SARI)
                        </Typography>
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flex: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        checked={simptomeClinice.includes(
                          "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)"
                        )}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSimptomeClinice((prevState) => [
                              ...prevState,
                              "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)",
                            ]);
                          } else {
                            setSimptomeClinice((prevState) =>
                              prevState.filter(
                                (e) =>
                                  e !=
                                  "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)"
                              )
                            );
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>
                        debut recent de anosmie (pierderea mirosului) sau
                        ageuzie (pierderea gustului)
                      </Typography>
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "15px",
                  }}
                >
                  <label alignItems="left" style={{ fontSize: 12 }}>
                    Alte simptome:
                  </label>
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      className={mystyle.textField}
                      defaultValue={simptomeClinice.filter((el) => {
                        return (
                          el != "febra" &&
                          el != "tuse" &&
                          el != "astenie" &&
                          el != "cefalee" &&
                          el != "mialgii" &&
                          el != "dureri in gat" &&
                          el != "coriza" &&
                          el != "dispnee" &&
                          el != "anorexie/greturi/varsaturi" &&
                          el != "diaree" &&
                          el != "status mental alterat" &&
                          el !=
                            "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)" &&
                          el != "pneumonie" &&
                          el != "bronhopneumonie" &&
                          el != "pleurezie" &&
                          el != "infectie respiratorie acuta severa (SARI)" &&
                          el != "dificultate în respiratie"
                        );
                      })}
                      onBlur={(event) => {
                        setSimptomeClinice((prevState) => [
                          ...prevState,
                          event.target.value,
                        ]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={mystyle.thirdColumn}>
              <div className={mystyle.label}>
                Organ control:{" "}
                <div className={mystyle.value}>
                  <TextField
                    value={organControl}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setOrganControl(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Institutie organ control:{" "}
                <div className={mystyle.value}>
                  <TextField
                    value={institutieControl}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setInstitutieControl(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Comentarii:
                <div className={mystyle.value}>
                  <TextField
                    value={comentarii}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setComentarii(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Ocupație:{" "}
                <div className={mystyle.value}>
                  <TextField
                    value={ocupatie}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setOcupatie(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Loc de muncă:{" "}
                <div className={mystyle.value}>
                  <TextField
                    value={locDeMunca}
                    alignItems="right"
                    className={mystyle.textField}
                    onChange={(event) => {
                      setLocDeMunca(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa domiciliu</div>
                <div className={mystyle.label}>
                  Județ domiciliu:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetDomiciliu(judet);
                          setLocalitateDomiciliu("");
                        }}
                        oldValue={judetDomiciliu}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateDomiciliu(localitate);
                        }}
                        oldValue={localitateDomiciliu}
                        selectedCounty={judetDomiciliu}
                      />
                    </div>
                  </div>
                </div>
                <div className={mystyle.label}>
                  Adresa
                  <div className={mystyle.value}>
                    <TextField
                      value={adresa}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setAdresa(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={adresaStrada}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setAdresaStrada(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={adresaNr}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setAdresaNr(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={adresaDetalii}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setAdresaDetalii(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa reala</div>
                <div className={mystyle.label}>
                  Județ adresa reală:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetAdresaReala(judet);
                          setLocalitateAdresaReala("");
                        }}
                        oldValue={judetAdresaReala}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateAdresaReala(localitate);
                        }}
                        oldValue={localitateAdresaReala}
                        selectedCounty={judetAdresaReala}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={stradaAdresaReala}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setStradaAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={nrAdresaReala}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setNrAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={detaliiAdresaReala}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setDetaliiAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa Izolare</div>
                <div className={mystyle.label}>
                  Județ adresa izolare:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetAdresaIzolare(judet);
                          setLocalitateAdresaIzolare("");
                        }}
                        oldValue={judetAdresaIzolare}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div className={mystyle.textField}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateAdresaIzolare(localitate);
                        }}
                        oldValue={localitateAdresaIzolare}
                        selectedCounty={judetAdresaIzolare}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={stradaAdresaIzolare}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setStradaAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={nrAdresaIzolare}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setNrAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      value={detaliiAdresaIzolare}
                      alignItems="right"
                      className={mystyle.textField}
                      onChange={(event) => {
                        setDetaliiAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabela contacti */}
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              flexDirection: "column",
              paddingTop: 5,
            }}
          >
            <button
              className={mystyle.addRecordButton}
              onClick={handleAddClick}
            >
              {addRowClicked ? (
                <>
                  <Cancel style={{ marginRight: 10 }} /> Renuntati
                </>
              ) : (
                <>
                  <AddIcon />
                  Adaugă un contact
                </>
              )}
            </button>
            <TabelAncheta
              data={fullTableData}
              getData={getData}
              addRowClicked={addRowClicked}
              numeSursa={selectedCase["nume"]}
              prenumeSursa={selectedCase["prenume"]}
              cnpSursa={selectedCase["cnp"] || ""}
              codCazSursa={nrCrt}
              doneOrCancel={() => {
                setAddRowClicked(false);
              }}
            />
          </div>
          {/* Buttons */}
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              height: 50,
            }}
          >
            <div>
              <button
                className={mystyle.cancelButton}
                onClick={() => {
                  props.hideModal();
                }}
              >
                Anulare
              </button>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button
                className={mystyle.addButtonModal}
                style={{ marginLeft: 10 }}
                onClick={() => save("Repartizata")}
              >
                Salvați
              </button>
              <button
                className={mystyle.addButtonModal}
                onClick={() => save("Finalizata")}
              >
                Finalizare
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
