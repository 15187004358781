import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Table, Input, Form } from "antd";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import DoctorTypeSelect from "./DoctorTypeSelect";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";

const EditableContext = React.createContext();
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="editing"
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={
          {
            // paddingRight: 24,
          }
        }
        // onClick={toggleEdit}
        onDoubleClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class Tabelmedici extends Component {
  handleSave = (row) => {
    let params = new FormData();
    params.append("type", "doctori");
    params.append("row", JSON.stringify(row));
    axios.post(pathString + "admin/update.php", params).then(() => {
      this.props.getData();
    });
  };

  handleDelete = (row) => {
    let params = new FormData();

    params.append("ID", row["ID"]);
    params.append("type", "doctori");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      this.props.getData();
    });
  };
  render() {
    const user = this.props.user;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    return (
      <Table
        components={components}
        rowClassName={(record, index) =>
          record.TYPE == "-1" ? "editable-row-dead" : "editable-row"
        }
        columns={[
          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Șterge",
                  render: (placeholder, record) => {
                    if (record.TYPE == 2 && user.ACCESS_LEVEL == 3) return null;
                    else
                      return (
                        <CustomDeleteComponent
                          handleDelete={() => this.handleDelete(record)}
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["TYPE"]
            ? [
                {
                  width: 100,
                  title: "Tip",
                  render: (placeholder, record) => {
                    return (
                      <DoctorTypeSelect
                        onChange={(type) => {
                          this.handleSave({ ...record, TYPE: type });
                        }}
                        oldType={record.TYPE}
                      />
                    );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["NUME"]
            ? [
                {
                  width: 250,
                  title: "Nume",
                  dataIndex: "NUME",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["medici"]["NUME"],
                    dataIndex: "NUME",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["PAROLA"]
            ? [
                {
                  width: 120,
                  title: "Telefon",
                  dataIndex: "PAROLA",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["medici"]["PAROLA"],
                    dataIndex: "PAROLA",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["NUME_CABINET"]
            ? [
                {
                  width: 600,
                  title: "Cabinet",
                  dataIndex: "NUME_CABINET",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["medici"]["NUME_CABINET"],
                    dataIndex: "NUME_CABINET",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["EMAIL"]
            ? [
                {
                  width: 300,
                  title: "E-mail",
                  dataIndex: "EMAIL",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["medici"]["EMAIL"],
                    dataIndex: "EMAIL",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["LOCALITATE"]
            ? [
                {
                  width: 200,
                  title: "Localitate",
                  dataIndex: "LOCALITATE",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["medici"]["LOCALITATE"]
                      )
                    )
                      return <span>{record.LOCALITATE}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(LOCALITATE) => {
                            this.handleSave({
                              ...record,
                              LOCALITATE: LOCALITATE,
                            });
                          }}
                          oldValue={record.LOCALITATE}
                          selectedCounty=""
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["medici"]["ADRESA"]
            ? [
                {
                  width: 550,
                  title: "Adresa",
                  dataIndex: "ADRESA",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["medici"]["ADRESA"],
                    dataIndex: "ADRESA",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
        ]}
        bordered
        rowKey={(record) => record.ID}
        loading={this.props.loading}
        dataSource={this.props.tableData}
        pagination={false}
        size="small"
        scroll={{ y: "calc(100vh - 180px)" }}
      />
    );
  }
}
