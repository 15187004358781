import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import axios from "axios";
import pathString from "./../../../get_php_link";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    minWidth: 150,
  },
});

export default function TabelIstoric(props) {
  const { tableData } = props;
  const classes = useStyles();

  const handleDelete = (row) => {
    let params = new FormData();

    params.append("cnp", row.CNP);
    params.append("type", "contacti");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      props.getData();
    });
  };

  const formatDate = (textDate) => {
    const date = new Date(textDate);
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    try {
      const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
      ] = dateTimeFormat.formatToParts(date);

      return `${day}.${month}.${year}`;
    } catch (err) {
      var year = "0000";
      var day = "00";
      var month = "00";

      return `${day}.${month}.${year}`;
    }

    // return textDate;
  };

  return (
    <TableContainer
      component={Paper}
      style={{ height: "100%", width: "100%" }}
      id="tableBody"
    >
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="a dense table"
        style={{ height: "100%" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.tableCell}>
              Utilizator
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Acțiune
            </TableCell>

            <TableCell align="center" className={classes.tableCell}>
              Informații
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow>
              <TableCell align="center">{row.user}</TableCell>
              <TableCell align="center">{row.action}</TableCell>
              <TableCell align="center">{row.info}</TableCell>
              <TableCell align="center">{row.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
