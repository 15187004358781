import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const formatDate = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
};

function DateSelect(props) {
  const [selectedDate, setSelectedDate] = useState(
    typeof props.initialValue == "undefined" ||
      props.initialValue == "0000-00-00 00:00:00"
      ? null
      : props.initialValue
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onChange(formatDate(date));
  };

  useEffect(() => {
    setSelectedDate(
      typeof props.initialValue == "undefined" ||
        props.initialValue == "0000-00-00 00:00:00"
        ? null
        : props.initialValue
    );
  }, [props.initialValue]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        error={false}
        helperText={null}
        style={{ fontSize: 12, width: props.width, margin: 0 }} //prev width was 280
        disableToolbar
        variant="inline"
        format="dd-MM-yyyy"
        margin="normal"
        readOnly={!props.editable}
        value={selectedDate}
        onChange={handleDateChange}
        inputProps={{
          readOnly: !props.editable,
          disabled: !props.editable,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateSelect;
