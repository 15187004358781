import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function DropdownNationalitate(props) {
  const { onChange, cetatenieVeche } = props;
  const editable = typeof props.editable == "undefined" ? true : props.editable;
  const [cetatenie, setCetatenie] = useState(cetatenieVeche);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (editable) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    setCetatenie(selectedType);
    onChange(selectedType);
  };

  useEffect(() => {
    setCetatenie(cetatenieVeche);
  }, [cetatenieVeche]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
      >
        {cetatenie}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ height: 30 }}
      >
        <MenuItem
          onClick={() => {
            handleTypeChange("Romana");
            handleClose();
          }}
        >
          Romana
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Alta");
            handleClose();
          }}
        >
          Alta
        </MenuItem>
      </Menu>
    </div>
  );
}
