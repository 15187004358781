import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  Snackbar,
} from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import mystyle from "./../mystyle.module.css";
import CustomDateSelect from "./../CommonComponents/CustomDateSelect";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import pathString from "./../../../get_php_link";

const BootstrapInput = withStyles((theme) => ({
  input: {
    width: 400,
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: 700,
    // height: 300,
  },
  details: {
    padding: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

export default function ModalArhiva(props) {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Descărcare arhivă
        </h2>
        <div className={classes.details}>
          <CustomDateSelect />
        </div>
      </div>
    </Modal>
  );
}
