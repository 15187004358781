import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Counties } from "../../utils";

export default function CustomCountyComponent(props) {
  const { onChange, oldValue } = props;

  const handleSelect = (e, value) => {
    var arg = value ? value.nume : "";
    onChange(arg);
  };

  return (
    <Autocomplete
      fullWidth
      disabled={false}
      onChange={handleSelect}
      options={Counties}
      getOptionLabel={(option) => option.nume}
      renderInput={(params) => (
        <TextField fullWidth {...params} autoComplete="off" />
      )}
      value={{ nume: oldValue }}
    />
  );
}
