import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Modal, Backdrop } from "@material-ui/core";
import mystyle from "./../mystyle.module.css";
import CustomDateIstoric from "./CustomDateIstoric";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "80vw",
    height: "80vh",
  },
  details: {
    padding: 20,
  },
}));

export default function ModalIstoric(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Istoric
        </h2>
        <div className={classes.details}>
          <CustomDateIstoric />
          {/* <TabelIstoric /> */}
        </div>
      </div>
    </Modal>
  );
}
