import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import axios from "axios";
import pathString from "../get_php_link";
import { useHistory } from "react-router-dom";
import { UserContext } from "./Context";
import mystyle from "./admin/mystyle.module.css";
import logo from "../img/sigla_dsp_verde.png";
import HistoryIcon from "@material-ui/icons/History";
import ModalIstoric from "./admin/Istoric/ModalIstoric";
import ModalAdmin from "./ModalAdmin";
import ModalCustomizare from "./ModalCustomizare";
import EditIcon from "@material-ui/icons/Edit";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

export default function CustomAppBar(props) {
  let history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [isModalIstoricVisible, setIsModalIstoricVisible] = useState(false);
  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);
  const [isUserColumnsModalVisible, setIsUserColumnsModalVisible] = useState(
    false
  );
  const showModalIstoric = () => {
    setIsModalIstoricVisible(true);
  };

  const showModalAdmin = () => {
    setIsAdminModalVisible(true);
  };

  const showModalCustomizare = () => {
    setIsUserColumnsModalVisible(true);
  };

  const hideModals = () => {
    setIsModalIstoricVisible(false);
    setIsAdminModalVisible(false);
    setIsUserColumnsModalVisible(false);
  };
  const logOut = () => {
    axios.post(pathString + "auth/logout.php").then(() => {
      history.push("/#");
      setUser({ IS_LOGGED: 0 });
    });
  };

  return (
    <div className={mystyle.header}>
      <img
        src={logo}
        alt="my image"
        style={{ height: 30, width: 30, color: "white", marginLeft: 10 }}
      />
      <span
        style={{
          fontWeight: "bold",
          fontSize: 20,
          color: "white",
          marginLeft: 10,
        }}
      >
        DSP Timiș COVID-19
      </span>
      <div style={{ display: "flex", flex: 1, flexDirection: "row-reverse" }}>
        <button onClick={logOut} className={mystyle.logoutButton}>
          <LogOutIcon style={{ color: "white", marginRight: 5 }} />
          Delogare
        </button>
        {user && (user.ACCESS_LEVEL == "2" || user.ACCESS_LEVEL == "3") && (
          <button
            className={mystyle.logoutButton}
            onClick={() => {
              showModalIstoric();
            }}
          >
            <HistoryIcon style={{ color: "white", marginRight: 5 }} />
            Istoric
          </button>
        )}
        {user && (user.ACCESS_LEVEL == "2" || user.ACCESS_LEVEL == "3") && (
          <button
            className={mystyle.logoutButton}
            onClick={() => {
              showModalAdmin();
            }}
          >
            <SupervisorAccountIcon style={{ color: "white", marginRight: 5 }} />
            Admin panel
          </button>
        )}
        <button
          className={mystyle.logoutButton}
          onClick={() => {
            showModalCustomizare();
          }}
        >
          <EditIcon style={{ color: "white", marginRight: 5 }} />
          Customizare
        </button>
      </div>
      <ModalIstoric
        isModalVisible={isModalIstoricVisible}
        hideModal={hideModals}
      />

      <ModalAdmin isModalVisible={isAdminModalVisible} hideModal={hideModals} />

      <ModalCustomizare
        isModalVisible={isUserColumnsModalVisible}
        hideModal={hideModals}
      />
    </div>
  );
}
