import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import DeleteIcon from "@material-ui/icons/Delete";

export default function CustomDeleteComponent(props) {
  const [clicked, setClicked] = useState(false);
  const { handleDelete } = props;

  const handleClick = () => {
    setClicked(true);
  };

  const handleNo = () => {
    setClicked(false);
  };

  if (clicked) {
    return (
      <div>
        <div style={{ textAlign: "center" }}>Ești sigur?</div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Link onClick={handleDelete}>DA</Link> /{" "}
          <Link onClick={handleNo}>NU</Link>
        </div>
      </div>
    );
  } else
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleClick}>
          <DeleteIcon color="secondary" />
        </IconButton>
      </div>
    );
}
