import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function StateDropdown(props) {
  const { onChange, stareVeche } = props;
  const editable = typeof props.editable == "undefined" ? true : props.editable;
  const [stare, setStare] = useState(stareVeche);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (editable) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    setStare(selectedType);
    onChange(selectedType);
  };

  useEffect(() => {
    setStare(stareVeche);
  }, [stareVeche]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        fullWidth={true}
        onClick={handleClick}
        style={{ height: 30 }}
      >
        {stare}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleTypeChange("Izolat");
            handleClose();
          }}
        >
          Izolat
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Carantinat");
            handleClose();
          }}
        >
          Carantinat
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Infirmat");
            handleClose();
          }}
        >
          Infirmat
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Vindecat");
            handleClose();
          }}
        >
          Vindecat
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Decedat");
            handleClose();
          }}
        >
          Decedat
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Negativ");
            handleClose();
          }}
        >
          Negativ
        </MenuItem>
      </Menu>
    </div>
  );
}
