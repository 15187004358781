import React, { useState, useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/LogInPage";
import RegisterPage from "./pages/RegisterPage";
import CovidMainPage from "./pages/CovidMainPage";
import AdminPage from "./pages/AdminPage";
import { UserContext } from "./components/Context";
import CustomAppBar from "./components/CustomAppBar";
import axios from "axios";
import pathString from "./get_php_link";
import "./css/styles.css";
import "antd/es/table/style/css"; // for css

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.post(pathString + "auth/checkLogin.php").then((response) => {
      setUser({
        ...user,
        ...response.data,
        VIEW_RIGHTS: response.data.VIEW_RIGHTS
          ? JSON.parse(response.data.VIEW_RIGHTS)
          : null,
        EDIT_RIGHTS: response.data.EDIT_RIGHTS
          ? JSON.parse(response.data.EDIT_RIGHTS)
          : null,
      });
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <>
            <CustomAppBar />
            <Route exact path="/search">
              <CovidMainPage />
            </Route>
            <Route exact path="/admin">
              <AdminPage />
            </Route>
          </>
        </Switch>
      </HashRouter>
    </UserContext.Provider>
  );
}

export default App;
