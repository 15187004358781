import React, { useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomAppBar from "./../components/CustomAppBar";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { UserContext } from "./../components/Context";
import axios from "axios";
import pathString from "./../get_php_link";
import Background from "./../img/doctors-resignations.jpg";
import mystyle from "../components/admin/mystyle.module.css";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: 100,
    paddingLeft: 30,
    width: "100%",
    height: "calc(100vh - 50px)",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    flex: 1,
    height: "100%",
  },
  formButtons: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 10,
    justifyContent: "space-between",
  },
  background: {
    position: "absolute",
    zIndex: -1,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    opacity: 0.4,
    width: "100%",
    height: "calc(100vh - 50px)",
  },
}));

export default function CovidMainPage(props) {
  const classes = useStyles();
  let history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchResponse, setSearchResponse] = useState(null);
  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          height: "80vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (user.IS_LOGGED != 1) {
    history.push("/#");
  }

  const startSearching = async () => {
    setSearching(true);
    let params = new FormData();
    params.append("search", searchText);
    console.log(searchText);
    let response = await axios
      .post(pathString + "user/search.php", params)
      .then((response) => {
        // resetField();
        return response.data;
      });
    setSearchResponse(response);
    setSearching(false);
  };

  const exportFile = () => {
    window.open(pathString + "helpers/export.php?search=" + searchText);
  };

  const resetField = () => {
    setSearchText("");
    setSearchResponse(null);
  };

  return (
    <div>
      <div className={classes.background}></div>

      <div className={classes.root}>
        <div className={classes.form}>
          <span style={{ fontSize: 30 }}>
            Căutați după <b>CNP</b>, nume sau prenume:{" "}
          </span>
          {searchResponse &&
          searchResponse.STATUS == "OK" &&
          searchResponse.USER_DATA &&
          searchResponse.USER_DATA.length > 1 ? (
            <Typography style={{ fontSize: 28, color: "red" }}>
              În urma căutării au fost găsite mai multe înregistrări. Vă rugăm
              să introduceți <b>CNP</b>-ul persoanei căutate:
            </Typography>
          ) : null}
          <TextField
            fullWidth
            style={{ marginTop: 20 }}
            name="Nume"
            variant="outlined"
            id="searchText"
            label="CNP, nume sau prenume"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <div className={classes.formButtons}>
            <button onClick={startSearching} className={mystyle.searchButton}>
              <SearchIcon />
              {searching ? <CircularProgress size={20} /> : "Caută"}
            </button>

            <button onClick={resetField} className={mystyle.resetButton}>
              <HighlightOffIcon />
              Resetează
            </button>
          </div>
          {searchResponse &&
            (searchResponse.STATUS == "OK" ? (
              searchResponse.EXTRA == 0 ? (
                <Typography
                  variant="body2"
                  color="primaryText"
                  align="center"
                  style={{ marginTop: 15, fontSize: 30 }}
                >
                  {searchResponse.INFO_MESSAGE}
                </Typography>
              ) : searchResponse.USER_DATA &&
                searchResponse.USER_DATA.length <= 1 ? (
                <>
                  <Typography
                    variant="body2"
                    color="primaryText"
                    align="center"
                    style={{ marginTop: 15, fontSize: 30 }}
                  >
                    {searchResponse.INFO_MESSAGE}
                  </Typography>
                  {searchResponse.EXTRA == 1 ? (
                    <button
                      onClick={exportFile}
                      className={mystyle.downloadButton}
                    >
                      Descărcați decizia
                    </button>
                  ) : null}
                </>
              ) : null
            ) : (
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                style={{ marginTop: 15, fontSize: 30 }}
              >
                Nu s-a putut stabili o conexiune cu serverul. Vă rugăm să
                reîncercați!
              </Typography>
            ))}
        </div>
      </div>
    </div>
  );
}
