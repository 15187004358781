import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Table, Input, Button, Popconfirm, Form } from "antd";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import DropdownPersonal from "./DropdownPersonal";
import DownloadSuspect from "./DownloadSuspect";

const EditableContext = React.createContext();
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="editing"
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onDoubleClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class TabelSuspecti extends Component {
  handleSave = (row) => {
    let params = new FormData();
    params.append("type", "suspecti");
    params.append("row", JSON.stringify(row));
    axios.post(pathString + "admin/update.php", params).then(() => {
      this.props.getData();
    });
  };

  handleDelete = (row) => {
    let params = new FormData();

    params.append("codCaz", row["cod caz"]);
    params.append("type", "suspecti");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      this.props.getData();
    });
  };

  render() {
    const user = this.props.user;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    return (
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        columns={[
          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 150,
                  title: "Descarcă formular",
                  render: (placeholder, record) => {
                    var searchText;
                    searchText = record["cod caz"];
                    return (
                      <DownloadSuspect
                        record={record}
                        searchText={searchText}
                      />
                    );
                  },
                },
              ]
            : []),
          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Șterge",
                  render: (placeholder, record) => {
                    return (
                      <CustomDeleteComponent
                        handleDelete={() => this.handleDelete(record)}
                      />
                    );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["cod caz"]
            ? [
                {
                  width: 250,
                  title: "Cod caz",
                  dataIndex: "cod caz",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["cod caz"],
                    dataIndex: "cod caz",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["spital"]
            ? [
                {
                  width: 250,
                  title: "Spital/DSP",
                  dataIndex: "spital",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["spital"],
                    dataIndex: "spital",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["laborator"]
            ? [
                {
                  width: 250,
                  title: "Laborator",
                  dataIndex: "laborator",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["laborator"],
                    dataIndex: "laborator",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["nr proba"]
            ? [
                {
                  width: 250,
                  title: "Nr. probă",
                  dataIndex: "nr proba",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["nr proba"],
                    dataIndex: "nr proba",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["nume"]
            ? [
                {
                  width: 250,
                  title: "Nume",
                  dataIndex: "nume",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["suspecti"]["nume"],
                    dataIndex: "nume",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["prenume"]
            ? [
                {
                  width: 130,
                  title: "Prenume",
                  dataIndex: "prenume",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["prenume"],
                    dataIndex: "prenume",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["cnp"]
            ? [
                {
                  width: 130,
                  title: "CNP",
                  dataIndex: "cnp",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["suspecti"]["cnp"],
                    dataIndex: "cnp",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["varsta"]
            ? [
                {
                  width: 130,
                  title: "Varsta",
                  dataIndex: "varsta",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["varsta"],
                    dataIndex: "varsta",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["personal"]
            ? [
                {
                  width: 200,
                  title: "Personal medico-sanitar",
                  dataIndex: "personal",
                  // sorter: (a, b) => a["personal"].localeCompare(b["personal"]),
                  render: (placeholder, record) => (
                    <DropdownPersonal
                      personalOldValue={record["personal"]}
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["suspecti"]["personal"]
                      }
                      onChange={(personal) =>
                        this.handleSave({
                          ...record,
                          personal: personal,
                        })
                      }
                    />
                  ),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["data debut"]
            ? [
                {
                  width: 130,
                  title: "Data debut",
                  dataIndex: "data debut",
                  render: (placeholder, record) => (
                    <span>{record["data debut"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["data debut"],
                    dataIndex: "data debut",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data trimiterii probei"]
            ? [
                {
                  width: 200,
                  title: "Data trimiterii probei",
                  dataIndex: "data trimiterii probei",
                  render: (placeholder, record) => (
                    <span>{record["data trimiterii probei"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["data trimiterii probei"],
                    dataIndex: "data trimiterii probei",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["suspecti"]["tip proba"]
            ? [
                {
                  width: 300,
                  title: "Tip probă",
                  dataIndex: "tip proba",
                  render: (placeholder, record) => (
                    <span>{record["tip proba"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["tip proba"],
                    dataIndex: "tip proba",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare spalatura"]
            ? [
                {
                  width: 250,
                  title: "Data spălătură sau aspirat nazal",
                  dataIndex: "data recoltare spalatura",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare spalatura"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["data recoltare spalatura"],
                    dataIndex: "data recoltare spalatura",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare exsudat nazal"]
            ? [
                {
                  width: 200,
                  title: "Data exsudat nazal",
                  dataIndex: "data recoltare exsudat nazal",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare exsudat nazal"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"][
                        "data recoltare exsudat nazal"
                      ],
                    dataIndex: "data recoltare exsudat nazal",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare exsudat nasofaringian"]
            ? [
                {
                  width: 200,
                  title: "Data exsudat nasofaringian",
                  dataIndex: "data recoltare exsudat nasofaringian",
                  render: (placeholder, record) => (
                    <span>
                      {record["data recoltare exsudat nasofaringian"]}
                    </span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"][
                        "data recoltare exsudat nasofaringian"
                      ],
                    dataIndex: "data recoltare exsudat nasofaringian",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare sputa"]
            ? [
                {
                  width: 200,
                  title: "Data sputa",
                  dataIndex: "data recoltare sputa",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare sputa"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["data recoltare sputa"],
                    dataIndex: "data recoltare sputa",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare aspirat traheo bronsic"]
            ? [
                {
                  width: 200,
                  title: "Data aspirat traheo-bronșic",
                  dataIndex: "data recoltare aspirat traheo bronsic",
                  render: (placeholder, record) => (
                    <span>
                      {record["data recoltare aspirat traheo bronsic"]}
                    </span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"][
                        "data recoltare aspirat traheo bronsic"
                      ],
                    dataIndex: "data recoltare aspirat traheo bronsic",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare produs de lavaj"]
            ? [
                {
                  width: 220,
                  title: "Data lavaj bronho-alveolar",
                  dataIndex: "data recoltare produs de lavaj",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare produs de lavaj"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"][
                        "data recoltare produs de lavaj"
                      ],
                    dataIndex: "data recoltare produs de lavaj",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare fragmente necroptice"]
            ? [
                {
                  width: 250,
                  title: "Data fragmente necroptice de pulmon",
                  dataIndex: "data recoltare fragmente necroptice",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare fragmente necroptice"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"][
                        "data recoltare fragmente necroptice"
                      ],
                    dataIndex: "data recoltare fragmente necroptice",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["data recoltare alta proba"]
            ? [
                {
                  width: 200,
                  title: "Data alt tip de probă",
                  dataIndex: "data recoltare alta proba",
                  render: (placeholder, record) => (
                    <span>{record["data recoltare alta proba"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["data recoltare alta proba"],
                    dataIndex: "data recoltare alta proba",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS &&
          user.VIEW_RIGHTS["suspecti"]["medic trimitator"]
            ? [
                {
                  width: 200,
                  title: "Medic trimițător",
                  dataIndex: "medic trimitator",
                  render: (placeholder, record) => (
                    <span>{record["medic trimitator"]}</span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["suspecti"]["medic trimitator"],
                    dataIndex: "medic trimitator",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
        ]}
        bordered
        rowKey={(record) => record["cod caz"]}
        loading={this.props.loading}
        dataSource={this.props.tableData}
        pagination={false}
        size="small"
        scroll={{ y: "calc(100vh - 180px)" }}
      />
    );
  }
}
