import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "./../components/Context";
import axios from "axios";
import pathString from "../get_php_link";

import mystyle from "../components/admin/mystyle.module.css";
import TabelCazuri from "../components/admin/Cazuri/TabelCazuri";
import TabelContacti from "../components/admin/Contacti/TabelContacti";
import TabelSuspecti from "../components/admin/Suspecti/TabelSuspecti";
import TabelDoctori from "../components/admin/Doctori/TabelDoctori";

import ModalAddCazuri from "../components/admin/Cazuri/ModalAddCazuri";
import ModalAddContact from "../components/admin/Contacti/ModalAddContact";
import ModalAddMedici from "../components/admin/Doctori/ModalAddMedici";
import ModalAddSuspecti from "../components/admin/Suspecti/ModalAddSuspecti";
import ModalArhiva from "../components/admin/Arhiva/ModalArhiva";

import SelectImportType from "../components/admin/Import/SelectImportType";
import SelectExportType from "../components/admin/Export/SelectExportType";
import CustomPaginationComponent from "../components/CustomPaginationComponent";

import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/List"; //

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 50px)",
    overflow: "hidden",
  },
}));

export default function AdminPage(props) {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const [lastClickedButton, setLastClickedButton] = useState("Cazuri COVID");
  const [isModalCazuriVisible, setIsModalCazuriVisible] = useState(false);
  const [isModalDoctoriVisible, setIsModalDoctoriVisible] = useState(false);
  const [isModalContactiVisible, setIsModalContactiVisible] = useState(false);
  const [isModalArhivaVisible, setIsModalArhivaVisible] = useState(false);
  const [isModalSuspectiVisible, setIsModalSuspectiVisible] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);
  const searchText = useRef(null);
  const [localitati, setLocalitati] = useState([]);
  const [stareSelectata, setStareSelectata] = useState([]);
  const [loading, setLoading] = useState(false);

  const [paginationDetails, setPaginationDetails] = useState({
    selectedPage: 1,
    numberOfRecordsPerPage: 20,
    numberOfPages: 1,
    numberOfRecords: 0,
  });

  const showModalCazuri = () => {
    setIsModalCazuriVisible(true);
  };

  const hideModalCazuri = () => {
    setIsModalCazuriVisible(false);
  };

  const showModalSuspecti = () => {
    setIsModalSuspectiVisible(true);
  };

  const hideModalSuspecti = () => {
    setIsModalSuspectiVisible(false);
  };
  const showModalArhiva = () => {
    setIsModalArhivaVisible(true);
  };

  const hideModalArhiva = () => {
    setIsModalArhivaVisible(false);
  };

  const showModalContacti = () => {
    setIsModalContactiVisible(true);
  };

  const hideModalContacti = () => {
    setIsModalContactiVisible(false);
  };

  const showModalDoctori = () => {
    setIsModalDoctoriVisible(true);
  };
  const hideModalDoctori = () => {
    setIsModalDoctoriVisible(false);
  };

  const formatDate = (textDate) => {
    const date = new Date(textDate);
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    try {
      const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
      ] = dateTimeFormat.formatToParts(date);

      return `${day}-${month}-${year}`;
    } catch (err) {
      var year = "0000";
      var day = "00";
      var month = "00";

      return `${day}-${month}-${year}`;
    }
  };

  const getData = (selectedPage = paginationDetails.selectedPage) => {
    setLoading(true);
    let parameter = new FormData();

    parameter.append("page", selectedPage);
    parameter.append(
      "numberOfRecords",
      paginationDetails.numberOfRecordsPerPage
    );
    parameter.append(
      "type",
      lastClickedButton == "Cazuri COVID"
        ? "cazuri"
        : lastClickedButton == "Doctori"
        ? "doctori"
        : // : lastClickedButton == "Suspecți"
          // ? "suspecti"
          "contacti"
    );
    parameter.append("searchText", searchText.current);

    axios
      .post(pathString + "admin/getData.php", parameter)
      .then((response) => {
        var returner = response.data;
        setTableData(returner.data);
        setFullTableData(returner.data);
        setPaginationDetails((prevState) => ({
          ...prevState,
          numberOfPages: returner.numberOfPages,
          selectedPage: selectedPage,
          count: returner.count,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(getData, [
    lastClickedButton,
    // paginationDetails.numberOfRecordsPerPage,
    // paginationDetails.selectedPage,
  ]);

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          height: "80vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (user.IS_LOGGED != 1) {
    history.push("/#");
  }

  const handleSearchChange = (event) => {
    searchText.current = event.target.value;
  };

  const clearSearch = () => {
    document.getElementById("searchText").value = "";
    searchText.current = "";
    getData(1);
  };

  const search = () => {
    getData(1);
  };

  const changeTab = (tab) => {
    setLastClickedButton(tab);
    setPaginationDetails((prevState) => ({
      ...prevState,
      selectedPage: 1,
    }));
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          // flex: 1,
          height: 60,
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 10,
        }}
      >
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <ModalAddCazuri
            getData={getData}
            isModalVisible={isModalCazuriVisible}
            hideModal={hideModalCazuri}
          />
          {/* <ModalAddSuspecti
            getData={getData}
            isModalVisible={isModalSuspectiVisible}
            hideModal={hideModalSuspecti}
          /> */}
          <ModalAddContact
            getData={getData}
            isModalVisible={isModalContactiVisible}
            hideModal={hideModalContacti}
          />
          <ModalAddMedici
            getData={getData}
            isModalVisible={isModalDoctoriVisible}
            hideModal={hideModalDoctori}
          />
          <ModalArhiva
            getData={getData}
            isModalVisible={isModalArhivaVisible}
            hideModal={hideModalArhiva}
          />
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <button
              className={
                lastClickedButton == "Cazuri COVID"
                  ? mystyle.buttonClicked
                  : mystyle.buttonNotClicked
              }
              onClick={() => {
                changeTab("Cazuri COVID");
              }}
            >
              Cazuri COVID
            </button>
            <button
              className={
                lastClickedButton == "Contacți"
                  ? mystyle.buttonClicked
                  : mystyle.buttonNotClicked
              }
              onClick={() => {
                changeTab("Contacți");
              }}
            >
              Contacți
            </button>
            {/* <button
              className={
                lastClickedButton == "Suspecți"
                  ? mystyle.buttonClicked
                  : mystyle.buttonNotClicked
              }
              onClick={() => {
                changeTab("Suspecți");
              }}
            >
              Suspecți
            </button> */}
            {(user.ACCESS_LEVEL == "2" || user.ACCESS_LEVEL == "3") && (
              <button
                className={
                  lastClickedButton == "Doctori"
                    ? mystyle.buttonClicked
                    : mystyle.buttonNotClicked
                }
                onClick={() => {
                  changeTab("Doctori");
                }}
              >
                Cadre medicale
              </button>
            )}
          </ButtonGroup>

          <TextField
            id="searchText"
            label="Căutați"
            variant="outlined"
            onKeyPress={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === "Enter") {
                search();
                ev.preventDefault();
              }
            }}
            // value={searchText}
            onChange={handleSearchChange}
            size="small"
            style={{ marginLeft: 20, width: 220 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon
                    style={{
                      marginRight: 5,
                      cursor: "pointer",
                      color: "grey",
                    }}
                    onClick={clearSearch}
                  />
                  <SearchIcon
                    style={{ cursor: "pointer", color: "grey" }}
                    onClick={search}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          {(user.ACCESS_LEVEL == "2" || user.ACCESS_LEVEL == "3") && (
            <SelectExportType />
          )}
          <SelectImportType getData={getData} />

          {user.ACCESS_LEVEL == "2" || user.ACCESS_LEVEL == "3" ? (
            <div style={{ display: "flex" }}>
              <button
                className={mystyle.archiveButton}
                onClick={() => {
                  showModalArhiva();
                }}
              >
                <ListIcon style={{ marginRight: 5 }} />
                Arhivă
              </button>
              <button
                className={mystyle.addButtonNotClicked}
                onClick={() => {
                  lastClickedButton == "Cazuri COVID"
                    ? showModalCazuri()
                    : lastClickedButton == "Doctori"
                    ? showModalDoctori()
                    : // : lastClickedButton == "Suspecți"
                      // ? showModalSuspecti()
                      showModalContacti();
                }}
              >
                <AddIcon />
                <span style={{ marginLeft: 5 }}>
                  {lastClickedButton == "Cazuri COVID"
                    ? "Adăugare caz"
                    : lastClickedButton == "Doctori"
                    ? "Adăugare medic"
                    : // : lastClickedButton == "Suspecți"
                      // ? "Adăugare suspect"
                      "Adăugare contact"}
                </span>
              </button>
            </div>
          ) : lastClickedButton == "Doctori" ? null : (
            <div style={{ display: "flex" }}>
              {/* <button
                className={mystyle.archiveButton}
                onClick={() => {
                  showModalArhiva();
                }}
              >
                <ListIcon style={{ marginRight: 5 }} />
                Arhivă
              </button> */}
              <button
                className={mystyle.addButtonNotClicked}
                onClick={() => {
                  lastClickedButton == "Cazuri COVID"
                    ? showModalCazuri()
                    : lastClickedButton == "Doctori"
                    ? showModalDoctori()
                    : // : lastClickedButton == "Suspecți"
                      // ? showModalSuspecti()
                      showModalContacti();
                }}
              >
                <AddIcon />
                <span style={{ marginLeft: 5 }}>
                  {lastClickedButton == "Cazuri COVID"
                    ? "Adăugare caz"
                    : lastClickedButton == "Doctori"
                    ? "Adăugare medic"
                    : // : lastClickedButton == "Suspecți"
                      // ? "Adăugare suspect"
                      "Adăugare contact"}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          height: "calc(100vh - 110px)",
          overflow: "auto",
          padding: 10,
        }}
      >
        {lastClickedButton == "Cazuri COVID" ? (
          <TabelCazuri
            user={user}
            tableData={tableData}
            loading={loading}
            getData={getData}
          />
        ) : lastClickedButton == "Doctori" ? (
          <TabelDoctori
            user={user}
            tableData={tableData}
            loading={loading}
            getData={getData}
          />
        ) : (
          // : lastClickedButton == "Suspecți" ? (
          //   <TabelSuspecti
          //     user={user}
          //     tableData={tableData}
          //     loading={loading}
          //     getData={getData}
          //   />
          // )
          <TabelContacti
            user={user}
            tableData={tableData}
            loading={loading}
            getData={getData}
          />
        )}
        <CustomPaginationComponent
          paginationDetails={paginationDetails}
          setPaginationDetails={setPaginationDetails}
          getData={getData}
        />
      </div>
    </div>
  );
}
