import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Typography } from "@material-ui/core";
import pathString from "./../../../get_php_link";
import axios from "axios";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export default function CustomRecomandareMedic(props) {
  const classes = useStyles();
  const { onChange, oldValue } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelect = (e, value) => {
    var arg = value ? value.nume : "";
    onChange(arg);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .post(pathString + "admin/getMedici.php")
        .then((response) => {
          var data = response.data;
          setOptions(data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setOptions([]);
        });
    }
  }, [open]);

  return (
    <Autocomplete
      fullWidth
      disableListWrap
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onBlur={(e) => onChange(e.target.value)}
      onKeyPress={(ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === "Enter") {
          ev.preventDefault();
          onChange(ev.target.value);
        }
      }}
      onChange={handleSelect}
      options={options}
      getOptionLabel={(option) => option.nume}
      defaultValue={{ nume: oldValue }}
      freeSolo
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          //   label="Search input"
          margin="normal"
          InputProps={{ ...params.InputProps, type: "search" }}
          autoComplete="off"
        />
      )}
      renderOption={(option) => <Typography noWrap>{option.nume}</Typography>}
      groupBy={(option) => option.nume[0].toUpperCase()}
    />
  );
}
