import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function StatusAnchetaDropdown(props) {
  const { onChange, statusAnchetaVeche } = props;
  const editable = typeof props.editable == "undefined" ? true : props.editable;
  const [statusAncheta, setStatusAncheta] = useState(statusAnchetaVeche);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (editable) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    setStatusAncheta(selectedType);
    onChange(selectedType);
  };

  useEffect(() => {
    setStatusAncheta(statusAnchetaVeche);
  }, [statusAnchetaVeche]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {statusAncheta}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleTypeChange("Repartizata");
            handleClose();
          }}
        >
          Repartizata
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Nerepartizata");
            handleClose();
          }}
        >
          Nerepartizata
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Finalizata");
            handleClose();
          }}
        >
          Finalizata
        </MenuItem>
      </Menu>
    </div>
  );
}
