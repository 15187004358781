import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ModalAncheta from "./ModalAncheta";
import axios from "axios";
export default function CustomAncheta(props) {
  const selectedCase = props.record;
  const { record } = props;
  const [isModalAnchetaVisible, setIsModalAnchetaVisible] = useState(false);
  const showModalAncheta = () => {
    setIsModalAnchetaVisible(true);
  };

  const hideModalAncheta = () => {
    setIsModalAnchetaVisible(false);
  };
  const handleClick = () => {
    showModalAncheta();
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isModalAnchetaVisible && (
        <ModalAncheta
          selectedCase={selectedCase}
          isModalVisible={isModalAnchetaVisible}
          hideModal={hideModalAncheta}
          getData={props.getData}
        />
      )}
      <IconButton onClick={handleClick}>
        <EditIcon style={{ color: "#A2468C" }} />
      </IconButton>
    </div>
  );
}
