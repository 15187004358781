import React, { useState, useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import DropdownNationalitate from "./../CommonComponents/DropdownNationalitate";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import StateDropdown from "./../CommonComponents/StateDropdown";

import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import DateSelect from "./../CommonComponents/DateSelect";

import axios from "axios";
import pathString from "./../../../get_php_link";

export default function RandTabelAncheta(props) {
  const { row, handleDelete, codCazSursa } = props;
  const [clicked, setClicked] = useState(false);

  const [state, setState] = useState(row);

  const handleDoubleClick = useCallback(() => {
    setClicked(true);
  }, []);

  const handleCancel = () => {
    setClicked(false);
  };
  const formatDate = (strDate) => {
    if (strDate) {
      let arr = strDate.split(".");
      if (arr.length == 3) return arr[2] + "-" + arr[1] + "-" + arr[0];
      return strDate;
    }
  };
  const handleSave = () => {
    let params = new FormData();
    params.append("type", "contacti");
    params.append("row", JSON.stringify(state));
    axios.post(pathString + "admin/update.php", params).then(() => {
      setClicked(false);
    });
  };

  const updateState = (field, value) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  if (clicked)
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <CustomSaveComponent
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        </TableCell>
        <TableCell align="center">{state["cod caz"]}</TableCell>
        <TableCell align="center">{state["cod caz sursa"]}</TableCell>
        <TableCell align="center">
          <TextField
            id="nume"
            defaultValue={state["Nume"]}
            onBlur={(e) => updateState("Nume", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="telefon"
            defaultValue={state["Telefon"]}
            onBlur={(e) => updateState("Telefon", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <CustomCityComponent
            onChange={(localitate) => {
              updateState("Localitate", localitate);
            }}
            oldValue={state["Localitate"]}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="strada"
            defaultValue={state["Strada"]}
            onBlur={(e) => updateState("Strada", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="nrStrada"
            defaultValue={state["Nr. Strada"]}
            onBlur={(e) => updateState("Nr. Strada", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="detaliiAdresa"
            defaultValue={state["Detalii adresa"]}
            onBlur={(e) => updateState("Detalii adresa", e.target.value)}
          />
        </TableCell>

        <TableCell align="center">
          <DateSelect
            editable={true}
            onChange={(date) => updateState("Data intrare", date)}
            initialValue={formatDate(state["Data intrare"])}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="cnp"
            defaultValue={state["CNP"]}
            onBlur={(e) => updateState("CNP", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <DateSelect
            editable={true}
            onChange={(date) => updateState("Data iesire", date)}
            initialValue={formatDate(state["Data iesire"])}
          />
        </TableCell>
        <TableCell align="center">
          <StateDropdown
            stareVeche={state["Stare"]}
            onChange={(stare) => updateState("Stare", stare)}
          />
        </TableCell>
        <TableCell align="center">
          <MedicSelectComponent
            onChange={(medFam) => {
              updateState("Medic Familie", medFam);
            }}
            oldValue={state["Medic Familie"]}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            id="email"
            defaultValue={state["Email"]}
            onBlur={(e) => updateState("Email", e.target.value)}
          />
        </TableCell>
        <TableCell align="center">
          <DropdownNationalitate
            nationalitateVeche={state["nationalitate"]}
            onChange={(nationalitate) =>
              updateState("nationalitate", nationalitate)
            }
          />
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow onDoubleClick={handleDoubleClick}>
      <TableCell component="th" scope="row">
        <CustomDeleteComponent handleDelete={handleDelete} />
      </TableCell>
      <TableCell align="center">{state["cod caz"]}</TableCell>
      <TableCell align="center">{state["cod caz sursa"]}</TableCell>
      <TableCell align="center">{state["Nume"]}</TableCell>
      <TableCell align="center">{state["Telefon"]}</TableCell>
      <TableCell align="center">{state["Localitate"]}</TableCell>
      <TableCell align="center">{state["Strada"]}</TableCell>
      <TableCell align="center">{state["Nr. Strada"]}</TableCell>
      <TableCell align="center">{state["Detalii adresa"]}</TableCell>

      <TableCell align="center">{state["Data intrare"]}</TableCell>
      <TableCell
        align="center"
        style={{
          backgroundColor:
            state["CNP"] &&
            state["CNP"].length == 13 &&
            parseInt(state["CNP"][0]) != 0 &&
            parseInt(state["CNP"][0]) != 9 &&
            parseInt(state["CNP"].substring(3, 5)) <= 12 &&
            parseInt(state["CNP"].substring(5, 7)) <= 31
              ? "white"
              : "yellow",
        }}
      >
        {state["CNP"]}
      </TableCell>
      <TableCell align="center">{state["Data iesire"]}</TableCell>
      <TableCell align="center">{state["Stare"]}</TableCell>

      <TableCell align="center">{state["Medic Familie"]}</TableCell>
      <TableCell align="center">{state["Email"]}</TableCell>
      <TableCell align="center">{state["nationalitate"]}</TableCell>
    </TableRow>
  );
}

function CustomSaveComponent(props) {
  const { handleSave, handleCancel } = props;
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
      <IconButton onClick={handleSave}>
        <DoneIcon style={{ color: "green" }} />
      </IconButton>

      <IconButton onClick={handleCancel}>
        <ClearIcon style={{ color: "red" }} />
      </IconButton>
    </div>
  );
}
