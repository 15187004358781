import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import pathString from "./../../../get_php_link";
import mystyle from "./../mystyle.module.css";

class CustomDateSelect extends React.Component {
  constructor() {
    super();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 3);
    let endDate = new Date();
    this.state = {
      startDate: startDate,
      endDate: endDate,
      exportType: 0,
    };
  }

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };

  handleDateChange = (name, date) => {
    if (name === "startDate") {
      var startDate = date;
      this.setState({ startDate });
    } else if (name === "endDate") {
      var endDate = date;
      this.setState({ endDate });
    }
  };

  handleExportTypeChange = () => {
    if (this.state.exportType == 0) {
      this.setState({ exportType: 1 });
    } else {
      this.setState({ exportType: 0 });
    }
  };

  descarcaArhiva = () => {
    window.open(
      pathString +
        "helpers/exportBulk.php?startDate=" +
        this.formatDate(this.state.startDate) +
        "&endDate=" +
        this.formatDate(this.state.endDate) +
        "&typeExportBulk=" +
        this.state.exportType
    );
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          height: 200,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 20 }}>Selectați intervalul dorit: </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                label="Data de început"
                value={this.state.startDate}
                onChange={(e) => this.handleDateChange("startDate", e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                label="Data de sfârșit"
                value={this.state.endDate}
                onChange={(e) => this.handleDateChange("endDate", e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />{" "}
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 18 }}>Data intrare</span>
          <Switch
            checked={this.state.exportType == 1}
            onChange={this.handleExportTypeChange}
            color="default"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <span style={{ fontSize: 18 }}>Data import</span>
        </div>
        <button
          className={mystyle.downloadArchiveButton}
          onClick={this.descarcaArhiva}
        >
          Descărcați arhiva
        </button>
      </div>
    );
  }
}

export default CustomDateSelect;
