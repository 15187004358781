import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CustomDropZone(props) {
  const {
    setSelectedFileToUpload,
    selectedFileToUpload,
    uploadingFile,
  } = props;
  //Dropzone settings

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length != 0) setSelectedFileToUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (uploadingFile) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      {...getRootProps({
        style: {
          minHeight: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      })}
    >
      <input {...getInputProps()} />
      {selectedFileToUpload ? (
        <p>{selectedFileToUpload.name}</p>
      ) : (
        <p>
          Adăugați fișiserul prin „drag and drop” sau dați click aici pentru a
          selecta un fișiser!
        </p>
      )}
    </div>
  );
}
