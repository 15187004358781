import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import mystyle from "./../mystyle.module.css";

export default function DownloadErrorComponent(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <IconButton onClick={handleClickOpen}>
        <DownloadIcon style={{ color: "grey" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ width: "30vw", height: "30vh", backgroundColor: "pink" }}
      /> */}
      <IconButton onClick={handleClickOpen}>
        <DownloadIcon style={{ color: "grey" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>Date insuficiente pentru a genera decizia.</div>
            <div>Contactați covid19dsptimis@dsptimis.ro</div>
          </DialogContentText>
          {/* <IconButton
            onClick={handleClose}
            color="primary"
            autoFocus
            style={{ width: 50, display: "flex", flexDirection: "row-reverse" }}
          >
            <HighlightOffIcon style={{ color: "red" }} />
          </IconButton> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
