import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  Snackbar,
} from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import mystyle from "./../mystyle.module.css";
import DoctorTypeSelect from "./DoctorTypeSelect";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";

const BootstrapInput = withStyles((theme) => ({
  input: {
    width: 400,
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: 500,
    height: 425,
  },
  details: {
    padding: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

export default function ModalAddMedici(props) {
  const [nume, setNume] = React.useState("");
  const [numeCabinet, setNumeCabinet] = React.useState("");
  const [localitate, setLocalitate] = React.useState("");
  const [adresa, setAdresa] = React.useState("");
  const [parola, setParola] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [tip, setTip] = React.useState(0);
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const reset = () => {
    setNume("");
    setNumeCabinet("");
    setLocalitate("");
    setAdresa("");
    setParola("");
    setEmail("");
    setTip(0);
    setSnackbarOpen("");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const adauga = () => {
    if (nume == "" || numeCabinet == "") {
      handleSnackbarOpen();
    } else {
      let params = new FormData();
      params.append("type", "doctori");

      if (nume != "") {
        params.append("NUME", nume);
      }
      if (numeCabinet != "") {
        params.append("NUME_CABINET", numeCabinet);
      }
      if (adresa != "") {
        params.append("ADRESA", adresa);
      }
      if (localitate != "") {
        params.append("LOCALITATE", localitate);
      }
      if (parola != "") {
        params.append("PAROLA", parola);
      }

      if (email != "") {
        params.append("EMAIL", email);
      }

      params.append("TIP", tip);

      axios
        .post(pathString + "admin/insert.php", params)
        .then(() => {
          props.getData();
          props.hideModal();
          reset();
        })
        .catch((e) => {
          reset();
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Adăugare medic
        </h2>
        <div className={classes.details}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message="Vă rugăm completați toate câmpurile necesare!"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 450,
              height: 500,
              padding: 5,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Nume*: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setNume(event.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Nume cabinet*: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setNumeCabinet(event.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Adresa: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setAdresa(event.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Localitate: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <div style={{ width: 280 }}>
                  <CustomCityComponent
                    onChange={(localitate) => {
                      setLocalitate(localitate);
                    }}
                    oldValue={localitate}
                    selectedCounty=""
                  />
                </div>
                {/* <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setLocalitate(event.target.value);
                  }}
                /> */}
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Parola: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setParola(event.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label alignItems="left">Email: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <TextField
                  alignItems="right"
                  style={{ width: 280 }}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
            </div>

            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <label alignItems="left">Tip: </label>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <DoctorTypeSelect
                  oldType={0}
                  onChange={(type) => setTip(type)}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <button
                  className={mystyle.cancelButton}
                  onClick={() => {
                    props.hideModal();
                  }}
                >
                  Anulare
                </button>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <button className={mystyle.addButtonModal} onClick={adauga}>
                  Adăugare
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
