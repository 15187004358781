import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, TextField, Snackbar } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import mystyle from "./mystyleCazuri.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StateDropdown from "./../CommonComponents/StateDropdown";
import DateSelect from "./../CommonComponents/DateSelect";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";

import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import { Typography } from "@material-ui/core/";
import CustomRecomandareMedic from "./../CommonComponents/CustomRecomandareMedic";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "98vw",
    height: "90vh",
  },

  margin: {
    margin: theme.spacing(0.5),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export default function ModalAddCazuri(props) {
  const [nrPacient, setNrPacient] = React.useState("");
  const [nrCaz, setNrCaz] = React.useState("");
  const [tipDecizie, setTipDecizie] = React.useState("");
  const [nume, setNume] = React.useState("");
  const [prenume, setPrenume] = React.useState("");
  const [stare, setStare] = React.useState("Carantinat");
  const [CNP, setCNP] = React.useState("");
  const [sex, setsex] = React.useState("");
  const [dataDebut, setDataDebut] = React.useState(null);
  const [dataConfirmare, setDataConfirmare] = React.useState(null);
  const [dataRecoltare, setDataRecoltare] = React.useState(null);
  const [dataInternare, setDataInternare] = React.useState(null);
  const [dataExternare, setDataExternare] = React.useState(null);
  const [dataDeces, setDataDeces] = React.useState(null);
  const [nrDecedat, setNrDecedat] = React.useState("");
  const [telefon, setTelefon] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [serieCi, setSerieCi] = React.useState("");
  const [numarCi, setNumarCi] = React.useState("");
  const [ciEliberat, setCiEliberat] = React.useState("");
  const [dataEliberareCi, setDataEliberareCi] = React.useState(null);
  const [cetatenie, setCetatenie] = React.useState("");
  const [nrConfirmat, setNrConfirmat] = React.useState("");
  const [judetInfectare, setJudetInfectare] = React.useState("");
  const [siruta, setSiruta] = React.useState("");
  const [localitateInfectare, setLocalitateInfectare] = React.useState("");
  const [spital, setSpital] = React.useState("");
  const [tratament, setTratament] = React.useState("");
  const [linkEpidemiologic, setLinkEpidemiologic] = React.useState("");
  const [laborator, setLaborator] = React.useState("");
  const [nrTestPcr, setNrTestPcr] = React.useState("");
  const [dataTestPcr, setDataTestPcr] = React.useState(null);
  const [recomandareDr, setRecomandareDr] = React.useState("");
  const [medic, setMedic] = React.useState("");
  const [motivCarantinare, setMotivCarantinare] = React.useState("");
  const [simptomeClinice, setSimptomeClinice] = React.useState([]);
  const [teste, setTeste] = React.useState("");
  const [comentarii, setComentarii] = React.useState("");
  const [ocupatie, setOcupatie] = React.useState("");
  const [locDeMunca, setLocDeMunca] = React.useState("");
  const [judetDomiciliu, setJudetDomiciliu] = React.useState("");
  const [localitateDomiciliu, setLocalitateDomiciliu] = React.useState("");

  const [adresa, setAdresa] = React.useState("");
  // const [adresaLocalitate, setAdresaLocalitate] = React.useState("");
  const [adresaStrada, setAdresaStrada] = React.useState("");
  const [adresaNr, setAdresaNr] = React.useState("");
  const [adresaDetalii, setAdresaDetalii] = React.useState("");
  const [judetAdresaReala, setJudetAdresaReala] = React.useState("");
  const [localitateAdresaReala, setLocalitateAdresaReala] = React.useState("");
  const [stradaAdresaReala, setStradaAdresaReala] = React.useState("");
  const [nrAdresaReala, setNrAdresaReala] = React.useState("");
  const [detaliiAdresaReala, setDetaliiAdresaReala] = React.useState("");
  const [judetAdresaIzolare, setJudetAdresaIzolare] = React.useState("");
  const [localitateAdresaIzolare, setLocalitateAdresaIzolare] = React.useState(
    ""
  );
  const [stradaAdresaIzolare, setStradaAdresaIzolare] = React.useState("");
  const [nrAdresaIzolare, setNrAdresaIzolare] = React.useState("");
  const [detaliiAdresaIzolare, setDetaliiAdresaIzolare] = React.useState("");
  const [organControl, setOrganControl] = React.useState("");
  const [institutieControl, setInstitutieControl] = React.useState("");

  const [requiredTextColor, setRequiredTextColor] = React.useState("black");

  const [requiredDateTextColor, setRequiredDateTextColor] = React.useState(
    "black"
  );
  const [
    requiredTestPcrTextColor,
    setRequiredTestPcrTextColor,
  ] = React.useState("black");
  const [
    requiredDataExternareTextColor,
    setRequiredDataExternareTextColor,
  ] = React.useState("black");
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [snackbarText, setsnackbarText] = React.useState(
    "Vă rugăm să completați toate câmpurile necesare!"
  );
  const reset = () => {
    setNrPacient("");
    setNrCaz("");
    setTipDecizie("");
    setNume("");
    setPrenume("");
    setStare("Carantinat");
    setCNP("");
    setsex("");
    setDataDebut(null);
    setDataConfirmare(null);
    setDataRecoltare(null);
    setDataInternare(null);
    setDataExternare(null);
    setDataDeces(null);
    setNrDecedat("");
    setTelefon("");
    setEmail("");
    setSerieCi("");
    setNumarCi("");
    setCiEliberat("");
    setDataEliberareCi(null);
    setCetatenie("");
    setNrConfirmat("");
    setJudetInfectare("");
    setSiruta("");
    setLocalitateInfectare("");
    setSpital("");
    setTratament("");
    setLinkEpidemiologic("");
    setLaborator("");
    setNrTestPcr("");
    setDataTestPcr(null);
    setRecomandareDr("");
    setMedic("");
    setMotivCarantinare("");
    setSimptomeClinice([]);
    setTeste("");
    setComentarii("");
    setOcupatie("");
    setLocDeMunca("");
    setJudetDomiciliu("");
    setLocalitateDomiciliu("");
    setAdresa("");
    // setAdresaLocalitate("");
    setAdresaStrada("");
    setAdresaNr("");
    setAdresaDetalii("");
    setJudetAdresaReala("");
    setLocalitateAdresaReala("");
    setStradaAdresaReala("");
    setNrAdresaReala("");
    setDetaliiAdresaReala("");
    setJudetAdresaIzolare("");
    setLocalitateAdresaIzolare("");
    setStradaAdresaIzolare("");
    setNrAdresaIzolare("");
    setDetaliiAdresaIzolare("");
    setOrganControl("");
    setInstitutieControl("");
    setRequiredTextColor("black");
    setRequiredDateTextColor("black");
    setRequiredDataExternareTextColor("black");
    setRequiredTestPcrTextColor("black");
    setsnackbarText("Vă rugăm să completați toate câmpurile necesare!");
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getDetaliiCNP = (CNPParam) => {
    var AN = parseInt(CNPParam.substring(1, 3));
    var LUNA = CNPParam.substring(3, 5);
    var ZI = CNPParam.substring(5, 7);

    if (CNPParam[0] == 1 || CNPParam[0] == 2) {
      AN = 1900 + AN;
    } else {
      AN = 2000 + AN;
    }

    let date = new Date();
    let currentYear = date.getFullYear();

    let dataNastere = AN + "-" + LUNA + "-" + ZI;
    let varsta = currentYear - AN;

    console.log(AN, LUNA, ZI, varsta, dataNastere);

    return { varsta, dataNastere };
  };

  const adauga = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

    if (nume == "" || prenume == "") {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setsnackbarText("Vă rugăm să completați toate câmpurile necesare!");
    } else if (
      ((dataDebut || dataDeces || dataExternare || dataInternare) &&
        dataDebut > today) ||
      dataDeces > today ||
      dataExternare > today ||
      dataInternare > today
    ) {
      handleSnackbarOpen();
      setRequiredDateTextColor("red");
      setsnackbarText(
        "Data de debut/deces/externare/internare/rezultat este mai mare decât cea de azi!"
      );
    } else if (dataExternare && dataExternare < dataInternare) {
      handleSnackbarOpen();
      setRequiredDataExternareTextColor("red");
      setsnackbarText("Data de externare este mai mica decat cea de internare");
    } else {
      let params = new FormData();
      params.append("type", "cazuri");
      if (nrPacient != "") {
        params.append("nrPacient", nrPacient);
      }
      if (nrCaz != "") {
        params.append("nrCaz", nrCaz);
      }
      if (tipDecizie != "") {
        params.append("tipDecizie", tipDecizie);
      }
      if (nume != "") {
        params.append("nume", nume);
      }
      if (prenume != "") {
        params.append("prenume", prenume);
      }
      if (stare != "") {
        params.append("stare", stare);
      }
      if (CNP != "") {
        params.append("CNP", CNP);
      }
      if (sex != "") {
        params.append("sex", sex);
      }
      if (dataDebut != "") {
        params.append("dataDebut", dataDebut);
      }
      if (dataConfirmare != "") {
        params.append("dataConfirmare", dataConfirmare);
      }
      if (dataRecoltare != "") {
        params.append("dataRecoltare", dataRecoltare);
      }
      if (dataInternare != "") {
        params.append("dataInternare", dataInternare);
      }
      if (dataExternare != "") {
        params.append("dataExternare", dataExternare);
      }
      if (dataDeces != "") {
        params.append("dataDeces", dataDeces);
      }
      if (nrDecedat != "") {
        params.append("nrDecedat", nrDecedat);
      }
      if (telefon != "") {
        params.append("telefon", telefon);
      }
      if (email != "") {
        params.append("email", email);
      }
      if (serieCi != "") {
        params.append("serieCi", serieCi);
      }
      if (numarCi != "") {
        params.append("numarCi", numarCi);
      }
      if (ciEliberat != "") {
        params.append("ciEliberat", ciEliberat);
      }
      if (dataEliberareCi != "") {
        params.append("dataEliberareCi", dataEliberareCi);
      }
      if (cetatenie != "") {
        params.append("cetatenie", cetatenie);
      }
      if (nrConfirmat != "") {
        params.append("nrConfirmat", nrConfirmat);
      }
      if (judetInfectare != "") {
        params.append("judetInfectare", judetInfectare);
      }
      if (siruta != "") {
        params.append("siruta", siruta);
      }
      if (localitateInfectare != "") {
        params.append("localitateInfectare", localitateInfectare);
      }
      if (spital != "") {
        params.append("spital", spital);
      }
      if (tratament != "") {
        params.append("tratament", tratament);
      }
      if (linkEpidemiologic != "") {
        params.append("linkEpidemiologic", linkEpidemiologic);
      }
      if (laborator != "") {
        params.append("laborator", laborator);
      }
      if (nrTestPcr != "") {
        params.append("nrTestPcr", nrTestPcr);
      }

      if (recomandareDr != "") {
        params.append("recomandareDr", recomandareDr);
      }
      if (medic != "") {
        params.append("medic", medic);
      }
      if (motivCarantinare != "") {
        params.append("motivCarantinare", motivCarantinare);
      }
      if (simptomeClinice != "") {
        params.append("simptomeClinice", simptomeClinice);
      }
      if (teste != "") {
        params.append("teste", teste);
      }
      if (comentarii != "") {
        params.append("comentarii", comentarii);
      }
      if (ocupatie != "") {
        params.append("ocupatie", ocupatie);
      }
      if (locDeMunca != "") {
        params.append("locDeMunca", locDeMunca);
      }
      if (judetDomiciliu != "") {
        params.append("judetDomiciliu", judetDomiciliu);
      }
      if (localitateDomiciliu != "") {
        params.append("localitateDomiciliu", localitateDomiciliu);
      }

      if (adresa != "") {
        params.append("adresa", adresa);
      }
      // if (adresaLocalitate != "") {
      //   params.append("adresaLocalitate", adresaLocalitate);
      // }
      if (adresaStrada != "") {
        params.append("adresaStrada", adresaStrada);
      }
      if (adresaNr != "") {
        params.append("adresaNr", adresaNr);
      }
      if (adresaDetalii != "") {
        params.append("adresaDetalii", adresaDetalii);
      }
      if (judetAdresaReala != "") {
        params.append("judetAdresaReala", judetAdresaReala);
      }
      if (localitateAdresaReala != "") {
        params.append("localitateAdresaReala", localitateAdresaReala);
      }
      if (stradaAdresaReala != "") {
        params.append("stradaAdresaReala", stradaAdresaReala);
      }
      if (nrAdresaReala != "") {
        params.append("nrAdresaReala", nrAdresaReala);
      }
      if (detaliiAdresaReala != "") {
        params.append("detaliiAdresaReala", detaliiAdresaReala);
      }
      if (judetAdresaIzolare != "") {
        params.append("judetAdresaIzolare", judetAdresaIzolare);
      }
      if (localitateAdresaIzolare != "") {
        params.append("localitateAdresaIzolare", localitateAdresaIzolare);
      }
      if (stradaAdresaIzolare != "") {
        params.append("stradaAdresaIzolare", stradaAdresaIzolare);
      }
      if (nrAdresaIzolare != "") {
        params.append("nrAdresaIzolare", nrAdresaIzolare);
      }
      if (detaliiAdresaIzolare != "") {
        params.append("detaliiAdresaIzolare", detaliiAdresaIzolare);
      }
      if (organControl != "") {
        params.append("organControl", organControl);
      }
      if (institutieControl != "") {
        params.append("institutieControl", institutieControl);
      }

      axios
        .post(pathString + "admin/insert.php", params)
        .then(() => {
          props.getData();
          props.hideModal();
          reset();
        })
        .catch((e) => {
          reset();
        });
    }
  };
  // console.log(dataDebut);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={() => {
        props.hideModal();
        reset();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Adăugare caz
        </h2>
        <div className={classes.details}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                width: 450,
                height: 50,
                textAlign: "center",
                borderRadius: 10,
                color: "white",
                marginBottom: 30,
              }}
            >
              {snackbarText}
            </div>
          </Snackbar>
          <div className={mystyle.paper}>
            <div className={mystyle.firstColumn}>
              <div className={mystyle.label}>
                <label alignItems="left" style={{ fontSize: 12 }}>
                  Nr. pacient:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNrPacient(event.target.value);
                    }}
                  />
                </div>
              </div>
              {/* <div className={mystyle.label}>
                <label
                  alignItems="left"
                  style={{ color: requiredTextColor, fontSize: 12 }}
                >
                  Nr. caz:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNrCaz(event.target.value);
                    }}
                  />
                </div>
              </div> */}
              <div className={mystyle.label}>
                Tip decizie:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setTipDecizie(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                <label
                  alignItems="left"
                  style={{ color: requiredTextColor, fontSize: 12 }}
                >
                  Nume*:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNume(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                <label
                  alignItems="left"
                  style={{ color: requiredTextColor, fontSize: 12 }}
                >
                  Prenume*:
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setPrenume(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Stare:
                <div className={mystyle.value}>
                  <StateDropdown
                    stareVeche="Carantinat"
                    onChange={(stareDrop) => {
                      setStare(stareDrop);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                CNP:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setCNP(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Sex:
                <div className={mystyle.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={sex == "M"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setsex("M");
                          } else {
                            setsex("");
                          }
                        }}
                      />
                    }
                    label={<Typography style={{ fontSize: 12 }}>M</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={sex == "F"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setsex("F");
                          } else {
                            setsex("");
                          }
                        }}
                      />
                    }
                    label={<Typography style={{ fontSize: 12 }}>F</Typography>}
                  />
                </div>
              </div>
              {/* <div className={mystyle.labelDate}>
                Data debut*:
                <div className={mystyle.value}>
                  <DateSelect editable={true} onChange={(date) => setDataDebut(date)} />
                </div>
              </div> */}
              <div className={mystyle.labelDate}>
                Data debut:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataDebut(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Data confirmării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataConfirmare(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Data recoltării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataRecoltare(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Data internării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataInternare(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Data externării:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataExternare(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Data decesului:{" "}
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataDeces(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Nr. decedat:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNrDecedat(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Telefon:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setTelefon(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Email:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Serie CI:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    // inputProps={{ maxLength: 2 }}
                    value={serieCi}
                    onChange={(event) => {
                      let text = event.target.value;
                      if (text.length <= 2 && !isNumeric(text))
                        setSerieCi(text);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Numar CI:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    // inputProps={{ maxLength: 6 }}
                    value={numarCi}
                    onChange={(event) => {
                      let text = event.target.value;
                      if (text.length <= 6 && isNumeric(text))
                        setNumarCi(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                CI eliberat:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setCiEliberat(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.labelDate}>
                Data eliberare CI:
                <div className={mystyle.value}>
                  <DateSelect
                    editable={true}
                    onChange={(date) => setDataEliberareCi(date)}
                  />
                </div>
              </div>
              <div className={mystyle.labelDate}>
                Cetățenie:{" "}
                <div className={mystyle.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={cetatenie == "Romana"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setCetatenie("Romana");
                          } else {
                            setCetatenie("");
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>romana</Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        checked={cetatenie == "Alta"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setCetatenie("Alta");
                          } else {
                            setCetatenie("");
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>alta</Typography>
                    }
                  />
                </div>
              </div>
            </div>
            <div className={mystyle.secondColumn}>
              <div className={mystyle.label}>
                Nr. confirmat:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNrConfirmat(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Județ infectare:
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    {" "}
                    <CustomCountyComponent
                      onChange={(judet) => {
                        setJudetInfectare(judet);
                        setLocalitateInfectare("");
                      }}
                      oldValue={judetInfectare}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Siruta localitate infectare:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setSiruta(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Localitate infectare:
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    <CustomCityComponent
                      onChange={(localitate) => {
                        setLocalitateInfectare(localitate);
                      }}
                      oldValue={localitateInfectare}
                      selectedCounty={judetInfectare}
                    />
                  </div>
                </div>
              </div>
              <div className={mystyle.label}>
                Spital:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setSpital(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Teste:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setTeste(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Tratament:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setTratament(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Link epidemiologic:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setLinkEpidemiologic(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                Laborator:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setLaborator(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Nr. test pcr:{" "}
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setNrTestPcr(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Recomandare doctor:
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    <CustomRecomandareMedic
                      onChange={(doctor) => {
                        setRecomandareDr(doctor);
                      }}
                      oldValue={recomandareDr}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Medic de familie:{" "}
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    <MedicSelectComponent
                      onChange={(medicFam) => {
                        setMedic(medicFam);
                      }}
                      oldValue={medic}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                Motiv carantinare:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setMotivCarantinare(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>
                  <div style={{ fontWeight: "bold" }}>Simptome clinice</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "febra",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "febra")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            febra
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "mialgii",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "mialgii")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            milagii
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "diaree",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "diaree")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            diaree
                          </Typography>
                        }
                      />
                    </div>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "cefalee",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "cefalee")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            cefalee
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "astenie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "astenie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            astenie
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "pleurezie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "pleurezie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            pleurezie
                          </Typography>
                        }
                      />
                    </div>
                    <div className={mystyle.simptomeColoana}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "tuse",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "tuse")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>tuse</Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "coriza",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "coriza")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            coriza
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "pneumonie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "pneumonie")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            pneumonie
                          </Typography>
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 2,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "dureri in gat",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "dureri in gat")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            dureri in gat
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "dispnee",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter((e) => e != "dispnee")
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            dispnee
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedC"
                            color="primary"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSimptomeClinice((prevState) => [
                                  ...prevState,
                                  "bronhopneumonie",
                                ]);
                              } else {
                                setSimptomeClinice((prevState) =>
                                  prevState.filter(
                                    (e) => e != "bronhopneumonie"
                                  )
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 12 }}>
                            bronhopneumonie
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mystyle.simptomeColoana}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "status mental alterat",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "status mental alterat"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          status mental alterat
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "dificultate in respiratie",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "dificultate in respiratie"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          dificultate in respiratie
                        </Typography>
                      }
                    />
                  </div>
                  <div className={mystyle.simptomeColoana}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "anorexie/greturi/varsaturi",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) => e != "anorexie/greturi/varsaturi"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          anorexie/greturi/varsaturi
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSimptomeClinice((prevState) => [
                                ...prevState,
                                "infectie respiratorie acuta severa (SARI)",
                              ]);
                            } else {
                              setSimptomeClinice((prevState) =>
                                prevState.filter(
                                  (e) =>
                                    e !=
                                    "infectie respiratorie acuta severa (SARI)"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 12 }}>
                          infectie respiratorie acuta severa (SARI)
                        </Typography>
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flex: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSimptomeClinice((prevState) => [
                              ...prevState,
                              "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)",
                            ]);
                          } else {
                            setSimptomeClinice((prevState) =>
                              prevState.filter(
                                (e) =>
                                  e !=
                                  "debut recent de anosmie (pierderea mirosului) sau ageuzie (pierderea gustului)"
                              )
                            );
                          }
                        }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>
                        debut recent de anosmie (pierderea mirosului) sau
                        ageuzie (pierderea gustului)
                      </Typography>
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <label alignItems="left" style={{ fontSize: 12 }}>
                    Alte simptome:
                  </label>
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onBlur={(event) => {
                        setSimptomeClinice((prevState) => [
                          ...prevState,
                          event.target.value,
                        ]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={mystyle.thirdColumn}>
              <div className={mystyle.label}>
                Organ control:{" "}
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setOrganControl(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Institutie organ control:{" "}
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setInstitutieControl(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Comentarii:
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setComentarii(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Ocupație:{" "}
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setOcupatie(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                Loc de muncă:{" "}
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    onChange={(event) => {
                      setLocDeMunca(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa domiciliu</div>
                <div className={mystyle.label}>
                  Județ domiciliu:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetDomiciliu(judet);
                          setLocalitateDomiciliu("");
                        }}
                        oldValue={judetDomiciliu}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateDomiciliu(localitate);
                        }}
                        oldValue={localitateDomiciliu}
                        selectedCounty={judetDomiciliu}
                      />
                    </div>
                  </div>
                </div>
                <div className={mystyle.label}>
                  Adresa
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setAdresa(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setAdresaStrada(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setAdresaNr(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setAdresaDetalii(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa reala</div>
                <div className={mystyle.label}>
                  Județ adresa reală:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetAdresaReala(judet);
                          setLocalitateAdresaReala("");
                        }}
                        oldValue={judetAdresaReala}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateAdresaReala(localitate);
                        }}
                        oldValue={localitateAdresaReala}
                        selectedCounty={judetAdresaReala}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setStradaAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setNrAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setDetaliiAdresaReala(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.adresaBox}>
                <div className={mystyle.label}>Adresa Izolare</div>
                <div className={mystyle.label}>
                  Județ adresa izolare:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCountyComponent
                        onChange={(judet) => {
                          setJudetAdresaIzolare(judet);
                          setLocalitateAdresaIzolare("");
                        }}
                        oldValue={judetAdresaIzolare}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Localitate:{" "}
                  <div className={mystyle.value}>
                    <div style={{ width: 280 }}>
                      <CustomCityComponent
                        onChange={(localitate) => {
                          setLocalitateAdresaIzolare(localitate);
                        }}
                        oldValue={localitateAdresaIzolare}
                        selectedCounty={judetAdresaIzolare}
                      />
                    </div>
                  </div>
                </div>

                <div className={mystyle.label}>
                  Strada:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setStradaAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={mystyle.label}>
                  Nr:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setNrAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={mystyle.label}>
                  Detalii:{" "}
                  <div className={mystyle.value}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      onChange={(event) => {
                        setDetaliiAdresaIzolare(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <button
                className={mystyle.cancelButton}
                onClick={() => {
                  props.hideModal();
                  reset();
                }}
              >
                Anulare
              </button>
            </div>
            <div
              style={{ flex: 1, display: "flex", flexDirection: "row-reverse" }}
            >
              <button className={mystyle.addButtonModal} onClick={adauga}>
                Adăugare
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
