import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function DropdownPersonal(props) {
  const { onChange, personalOldValue } = props;
  const editable = typeof props.editable == "undefined" ? true : props.editable;
  const [personal, setPersonal] = useState(personalOldValue);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (editable) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    setPersonal(selectedType);
    onChange(selectedType);
  };

  useEffect(() => {
    setPersonal(personalOldValue);
  }, [personalOldValue]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {personal}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleTypeChange("Da");
            handleClose();
          }}
        >
          Da
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleTypeChange("Nu");
            handleClose();
          }}
        >
          Nu
        </MenuItem>
      </Menu>
    </div>
  );
}
