import React, { useEffect, useContext, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Modal, Backdrop } from "@material-ui/core";
import mystyle from "./admin/mystyle.module.css";
import { UserContext } from "./Context";
import axios from "axios";
import pathString from "../get_php_link";
import Switch from "@material-ui/core/Switch";
import columnMapping from "./utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "90vw",
    height: "80vh",
  },
  details: {
    padding: 20,
    display: "flex",
    overflow: "auto",
    height: "70vh",
  },
  typeContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  typeColumn: {
    flex: 1,
    display: "flex",
  },
}));

export default function ModalAdmin(props) {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const [data, setData] = useState([]);
  const types = {
    0: "Medic familie",
    1: "Contribuitor",
    2: "Admin",
    3: "Moderator",
    "-1": "Dezactivat",
  };

  const tableTypes = {
    cazuri: "Cazuri COVID",
    contacti: "Contacti",
    suspecti: "Suspecti",
    medici: "Cadre medicale",
  };

  const getRights = () => {
    if (user && user.ACCESS_LEVEL) {
      let params = new FormData();
      params.append("type", user.ACCESS_LEVEL);
      axios
        .post(pathString + "admin/getEditRights.php", params)
        .then((response) => {
          let result = [];
          response.data.forEach((record) => {
            result.push({ ...record, RIGHTS: JSON.parse(record.RIGHTS) });
          });
          setData(result);
        });
    }
  };
  useEffect(getRights, [user]);

  const save = () => {
    let params = new FormData();
    params.append("data", JSON.stringify(data));
    axios.post(pathString + "admin/saveRights.php", params).then((response) => {
      getRights();
    });
    props.hideModal();
    var newRights;

    data.forEach((record) => {
      if (record.TYPE == user.ACCESS_LEVEL) {
        newRights = record.RIGHTS;
      }
    });
    setUser((user) => ({ ...user, EDIT_RIGHTS: newRights }));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={props.hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className={mystyle.headerModal}
        >
          <div style={{ flex: 1 }}></div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 id="transition-modal-title" style={{ color: "white" }}>
              Admin panel
            </h3>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <button
              style={{ fontSize: 15 }}
              className={mystyle.logoutButton}
              onClick={save}
            >
              Salvați
            </button>
          </div>
        </div>
        <div className={classes.details}>
          {data.map((record, index) => {
            return (
              <div
                className={classes.typeContainer}
                key={index + "" + record.TYPE}
              >
                <span style={{ fontSize: 26 }}>{types[record.TYPE]}</span>
                <div className={classes.typeColumn}>
                  {Object.keys(record.RIGHTS).map((key, rightsIndex) => {
                    return (
                      <div
                        key={key + rightsIndex}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span style={{ fontSize: 20 }}>{tableTypes[key]}</span>
                        {Object.keys(record.RIGHTS[key]).map(
                          (column, columnIndex) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                key={column + columnIndex}
                              >
                                <span>{columnMapping[column]}</span>{" "}
                                <Switch
                                  defaultChecked={record.RIGHTS[key][column]}
                                  onChange={(e) => {
                                    record.RIGHTS[key][column] = !record.RIGHTS[
                                      key
                                    ][column];
                                    setData(JSON.parse(JSON.stringify(data)));
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
