import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomSaveComponent from "./CustomSaveComponent";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import DropdownNationalitate from "./../CommonComponents/DropdownNationalitate";
import DateSelect from "./../CommonComponents/DateSelect";
import StateDropdown from "./../CommonComponents/StateDropdown";

import RandTabelAncheta from "./RandTabelAncheta";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    minWidth: 250,
  },
});

export default function TabelAncheta(props) {
  const {
    data,
    addRowClicked,
    numeSursa,
    prenumeSursa,
    cnpSursa,
    codCazSursa,
  } = props;
  const classes = useStyles();
  const [localitate, setLocalitate] = React.useState("");
  const [medicFamilie, setMedicFamilie] = React.useState("");
  const [nationalitate, setNationalitate] = React.useState("");
  const [stare, setStare] = React.useState("Carantinat");
  const [dataIntrare, setDataIntrare] = React.useState(null);
  const [dataIesire, setDataIesire] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const handleDelete = (row) => {
    let params = new FormData();
    setLoading(true);
    params.append("nrCaz", row["cod caz"]);
    params.append("type", "contacti");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      props.getData();
      setLoading(false);
    });
  };
  const formatDate = (strDate) => {
    if (strDate) {
      let arr = strDate.split(".");
      if (arr.length == 3) return arr[2] + "-" + arr[1] + "-" + arr[0];
      return strDate;
    }
  };
  useEffect(() => {
    if (addRowClicked == true) {
      var newRow = document.getElementById("scrollToThis");
      var topPos = newRow.offsetTop;
      document.getElementById("tableBody").scrollTop = topPos; //200
    }
  }, [addRowClicked]);
  // console.log(formatDate(dataIntrare));
  return (
    <TableContainer
      component={Paper}
      style={{ height: "31vh", width: "100%" }}
      id="tableBody"
    >
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ minWidth: 70, width: 70 }}>
              Șterge
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Cod contact
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Cod caz sursă
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Nume
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Telefon
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Localitate
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Strada
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Nr. stradă
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Detalii adresă
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Data intrare
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              CNP
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Data ieșire
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Stare
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Medic familie
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Email
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Naționalitate
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <RandTabelAncheta
              key={row["cod caz"] + index}
              row={row}
              handleDelete={() => handleDelete(row)}
              codCazSursa={codCazSursa}
            />
          ))}
          {addRowClicked ? (
            <TableRow key="addWhatever" id="scrollToThis">
              <TableCell component="th" scope="row">
                <CustomSaveComponent
                  doneOrCancel={props.doneOrCancel}
                  numeSursa={numeSursa}
                  prenumeSursa={prenumeSursa}
                  cnpSursa={cnpSursa}
                  getData={props.getData}
                  codCazSursa={codCazSursa}
                  localitate={localitate}
                  medicFamilie={medicFamilie}
                  nationalitate={nationalitate}
                  stare={stare}
                  dataIntrare={dataIntrare}
                  dataIesire={dataIesire}
                />
              </TableCell>
              <TableCell align="center">Va fi generat</TableCell>
              <TableCell align="center">{codCazSursa}</TableCell>
              {/* <TableCell align="center">{cnpSursa}</TableCell> */}
              {/* <TableCell align="center">
                <TextField id="cod caz sursa" />
              </TableCell> */}
              <TableCell align="center">
                <TextField id="nume" />
              </TableCell>
              <TableCell align="center">
                <TextField id="telefon" />
              </TableCell>
              <TableCell align="center">
                <CustomCityComponent
                  onChange={(localitate) => {
                    setLocalitate(localitate);
                  }}
                  oldValue={localitate}
                  selectedCounty=""
                />
              </TableCell>
              <TableCell align="center">
                <TextField id="strada" />
              </TableCell>
              <TableCell align="center">
                <TextField id="nrStrada" />
              </TableCell>
              <TableCell align="center">
                <TextField id="detaliiAdresa" />
              </TableCell>
              <TableCell align="center">
                <DateSelect
                  editable={true}
                  onChange={(date) => setDataIntrare(formatDate(date))}
                  // initialValue={dataIntrare}
                />
              </TableCell>
              <TableCell align="center">
                <TextField id="cnp" />
              </TableCell>
              <TableCell align="center">
                {/* <DateSelect
                editable={true}
                  onChange={(date) => setDataIesire(formatDate(date))}
                /> */}
                Va fi generată
              </TableCell>
              <TableCell align="center">
                {/* <TextField id="stare" /> */}
                <StateDropdown
                  stareVeche="Carantinat"
                  onChange={(stare) => setStare(stare)}
                />
              </TableCell>

              <TableCell align="center">
                <MedicSelectComponent
                  onChange={(medFam) => {
                    setMedicFamilie(medFam);
                  }}
                  oldValue={medicFamilie}
                />
              </TableCell>
              <TableCell align="center">
                <TextField id="email" />
              </TableCell>
              <TableCell align="center">
                <DropdownNationalitate
                  onChange={(nationalitate) => setNationalitate(nationalitate)}
                />
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
