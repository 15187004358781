import React, { useState, useEffect } from "react";
const styles = {
  button: {
    backgroundColor: "white",
    marginLeft: 5,
    color: "grey",
    border: "1px solid grey",
    outline: "none",
    minWidth: 40,
  },
  selectedButton: {
    backgroundColor: "white",
    marginLeft: 5,
    color: "blue",
    border: "1px solid blue",
    outline: "none",
    minWidth: 40,
  },
};

export default function CustomPaginationComponent(props) {
  const { paginationDetails, setPaginationDetails, getData } = props;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row-reverse",
        marginTop: 10,
        alignItems: "center",
      }}
    >
      {paginationDetails.numberOfPages <= 5 ? (
        <div>
          {[...Array(paginationDetails.numberOfPages + 1).keys()].map(
            (item, index) => {
              if (item != 0)
                return (
                  <button
                    style={
                      paginationDetails.selectedPage == item
                        ? styles.selectedButton
                        : styles.button
                    }
                    onClick={() => {
                      getData(item);
                      setPaginationDetails((prevState) => {
                        return { ...prevState, selectedPage: item };
                      });
                    }}
                  >
                    {item}
                  </button>
                );
            }
          )}
        </div>
      ) : (
        <div>
          <button
            style={
              paginationDetails.selectedPage == 1
                ? styles.selectedButton
                : styles.button
            }
            onClick={() => {
              getData(1);
              setPaginationDetails((prevState) => {
                return { ...prevState, selectedPage: 1 };
              });
            }}
          >
            1
          </button>
          {paginationDetails.selectedPage == 1 ? (
            <>
              <button
                style={styles.button}
                onClick={() => {
                  getData(2);
                  setPaginationDetails((prevState) => {
                    return { ...prevState, selectedPage: 2 };
                  });
                }}
              >
                2
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(3);
                  setPaginationDetails((prevState) => {
                    return { ...prevState, selectedPage: 3 };
                  });
                }}
              >
                3
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(4);
                  setPaginationDetails((prevState) => {
                    return { ...prevState, selectedPage: 4 };
                  });
                }}
              >
                4
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(5);
                  setPaginationDetails((prevState) => {
                    return { ...prevState, selectedPage: 5 };
                  });
                }}
              >
                5
              </button>
              {paginationDetails.numberOfPages > 6 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(6);
                    setPaginationDetails((prevState) => {
                      return { ...prevState, selectedPage: 6 };
                    });
                  }}
                >
                  {">>"}
                </button>
              )}
            </>
          ) : paginationDetails.selectedPage ==
            paginationDetails.numberOfPages ? (
            <>
              {paginationDetails.selectedPage > 6 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    var pagesDown = paginationDetails.selectedPage - 5;
                    if (pagesDown < 1) {
                      pagesDown = 1;
                    }
                    getData(pagesDown);
                    setPaginationDetails((prevState) => {
                      return { ...prevState, selectedPage: pagesDown };
                    });
                  }}
                >
                  {"<<"}
                </button>
              )}
              <button
                style={styles.button}
                onClick={() => {
                  getData(paginationDetails.numberOfPages - 4);
                  setPaginationDetails((prevState) => {
                    return {
                      ...prevState,
                      selectedPage: paginationDetails.numberOfPages - 4,
                    };
                  });
                }}
              >
                {paginationDetails.numberOfPages - 4}
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(paginationDetails.numberOfPages - 3);
                  setPaginationDetails((prevState) => {
                    return {
                      ...prevState,
                      selectedPage: paginationDetails.numberOfPages - 3,
                    };
                  });
                }}
              >
                {paginationDetails.numberOfPages - 3}
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(paginationDetails.numberOfPages - 2);
                  setPaginationDetails((prevState) => {
                    return {
                      ...prevState,
                      selectedPage: paginationDetails.numberOfPages - 2,
                    };
                  });
                }}
              >
                {paginationDetails.numberOfPages - 2}
              </button>
              <button
                style={styles.button}
                onClick={() => {
                  getData(paginationDetails.numberOfPages - 1);
                  setPaginationDetails((prevState) => {
                    return {
                      ...prevState,
                      selectedPage: paginationDetails.numberOfPages - 1,
                    };
                  });
                }}
              >
                {paginationDetails.numberOfPages - 1}
              </button>
            </>
          ) : (
            <>
              {paginationDetails.selectedPage > 5 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    var pagesDown = paginationDetails.selectedPage - 5;
                    if (pagesDown < 1) {
                      pagesDown = 1;
                    }
                    getData(pagesDown);
                    setPaginationDetails((prevState) => {
                      return { ...prevState, selectedPage: pagesDown };
                    });
                  }}
                >
                  {"<<"}
                </button>
              )}
              {(paginationDetails.selectedPage ==
                paginationDetails.numberOfPages - 1 ||
                paginationDetails.selectedPage == 5) && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(paginationDetails.selectedPage - 3);
                    setPaginationDetails((prevState) => {
                      return {
                        ...prevState,
                        selectedPage: paginationDetails.selectedPage - 3,
                      };
                    });
                  }}
                >
                  {paginationDetails.selectedPage - 3}
                </button>
              )}
              {paginationDetails.selectedPage > 3 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(paginationDetails.selectedPage - 2);
                    setPaginationDetails((prevState) => {
                      return {
                        ...prevState,
                        selectedPage: paginationDetails.selectedPage - 2,
                      };
                    });
                  }}
                >
                  {paginationDetails.selectedPage - 2}
                </button>
              )}
              {paginationDetails.selectedPage > 2 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(paginationDetails.selectedPage - 1);
                    setPaginationDetails((prevState) => {
                      return {
                        ...prevState,
                        selectedPage: paginationDetails.selectedPage - 1,
                      };
                    });
                  }}
                >
                  {paginationDetails.selectedPage - 1}
                </button>
              )}
              <button style={styles.selectedButton}>
                {paginationDetails.selectedPage}
              </button>
              {paginationDetails.selectedPage <=
                paginationDetails.numberOfPages - 2 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(paginationDetails.selectedPage + 1);
                    setPaginationDetails((prevState) => {
                      return {
                        ...prevState,
                        selectedPage: paginationDetails.selectedPage + 1,
                      };
                    });
                  }}
                >
                  {paginationDetails.selectedPage + 1}
                </button>
              )}
              {paginationDetails.selectedPage <=
                paginationDetails.numberOfPages - 3 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(paginationDetails.selectedPage + 2);
                    setPaginationDetails((prevState) => {
                      return {
                        ...prevState,
                        selectedPage: paginationDetails.selectedPage + 2,
                      };
                    });
                  }}
                >
                  {paginationDetails.selectedPage + 2}
                </button>
              )}
              {paginationDetails.selectedPage == 2 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    getData(5);
                    setPaginationDetails((prevState) => {
                      return { ...prevState, selectedPage: 5 };
                    });
                  }}
                >
                  5
                </button>
              )}
              {paginationDetails.selectedPage ==
                paginationDetails.numberOfPages - 4 &&
                paginationDetails.numberOfPages > 6 && (
                  <button
                    style={styles.button}
                    onClick={() => {
                      getData(5);
                      setPaginationDetails((prevState) => {
                        return { ...prevState, selectedPage: 5 };
                      });
                    }}
                  >
                    {paginationDetails.numberOfPages - 1}
                  </button>
                )}
              {paginationDetails.selectedPage <
                paginationDetails.numberOfPages - 4 && (
                <button
                  style={styles.button}
                  onClick={() => {
                    var pagesUp = paginationDetails.selectedPage + 5;
                    if (pagesUp > paginationDetails.numberOfPages) {
                      pagesUp = paginationDetails.numberOfPages;
                    }
                    getData(pagesUp);
                    setPaginationDetails((prevState) => {
                      return { ...prevState, selectedPage: pagesUp };
                    });
                  }}
                >
                  {">>"}
                </button>
              )}
            </>
          )}

          <button
            style={
              paginationDetails.selectedPage == paginationDetails.numberOfPages
                ? styles.selectedButton
                : styles.button
            }
            onClick={() => {
              getData(paginationDetails.numberOfPages);
              setPaginationDetails((prevState) => {
                return {
                  ...prevState,
                  selectedPage: paginationDetails.numberOfPages,
                };
              });
            }}
          >
            {paginationDetails.numberOfPages}
          </button>
        </div>
      )}
      <span style={{ marginRight: 20, fontSize: 18 }}>
        Înregistrări găsite: {paginationDetails.count}
      </span>
    </div>
  );
}
