import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import CustomDialog from "./../../CustomDialog";
import CustomDropZone from "./../../CustomDropZone";
import Axios from "axios";
import pathString from "./../../../get_php_link";
import ImportIcon from "@material-ui/icons/ArrowUpward";
import TabelErori from "./TabelErori";
import mystyle from "./../mystyle.module.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectImportType(props) {
  const classes = useStyles();
  const [importType, setImportType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFileToUpload, setSelectedFileToUpload] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [importWasDone, setImportWasDone] = useState(false);
  const [errorsArray, setErrorsArray] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (type) => {
    setImportType(type);
    handleClose();
    setDialogOpen(true);
  };

  const handleUploadFile = () => {
    setUploadingFile(true);
    let params = new FormData();
    params.append("uploadedFile", selectedFileToUpload);
    setSelectedFileToUpload(null);

    var importScriptPath = "";
    if (importType == "cazuri") {
      importScriptPath = "admin/importExcelTotalCazuri.php";
    } else if (importType == "contacți") {
      importScriptPath = "admin/importExcelContacti.php";
    }

    setImportWasDone(false);
    setSelectedFileToUpload(null);
    setErrorsArray([]);

    Axios.post(pathString + importScriptPath, params)
      .then((response) => {
        const { stare } = response.data;
        if (stare == "OK") {
          setDialogOpen(false);

          setImportWasDone(false);
          setSelectedFileToUpload(null);
          setUploadingFile(false);
        } else {
          const { array } = response.data;
          setErrorsArray(array);
          setImportWasDone(true);
          setSelectedFileToUpload(null);
          setUploadingFile(false);
        }

        props.getData();
      })
      .catch((e) => {
        console.log(e);
        alert(
          "A apărut o problema la importarea fișierului. Verificați fișiserul si reîncercați!"
        );
        setDialogOpen(false);
        setSelectedFileToUpload(null);
        setUploadingFile(false);
      });
  };

  return (
    <div>
      <button
        className={mystyle.importButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onMouseEnter={handleClick}
        onClick={handleClick}
      >
        <ImportIcon />
        <span style={{ marginLeft: 5 }}> Importă fișier excel</span>
      </button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: 47, width: 300, maxWidth: 300 }}
      >
        <MenuItem
          style={{ width: 200 }}
          onClick={() => {
            handleItemClick("cazuri");
          }}
        >
          Importă cazuri
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleItemClick("contacți");
          }}
        >
          Importă contacți
        </MenuItem>
      </Menu>
      <CustomDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        handleReset={() => {
          setImportWasDone(false);
          setSelectedFileToUpload(null);
          setUploadingFile(false);
          setErrorsArray([]);
        }}
        dialogContent={() => {
          if (!importWasDone) {
            return (
              <CustomDropZone
                setSelectedFileToUpload={setSelectedFileToUpload}
                selectedFileToUpload={selectedFileToUpload}
                uploadingFile={uploadingFile}
              />
            );
          } else {
            return <TabelErori data={errorsArray} />;
          }
        }}
        dialogActions={() => {
          if (!importWasDone) {
            return (
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  color="secondary"
                  onClick={() => {
                    setDialogOpen(false);
                    setImportWasDone(false);
                    setSelectedFileToUpload(null);
                    setUploadingFile(false);
                    setErrorsArray([]);
                  }}
                >
                  Renunță
                </Button>
                <Button
                  color="primary"
                  disabled={selectedFileToUpload == null}
                  onClick={() => {
                    handleUploadFile();
                  }}
                >
                  Importă
                </Button>
              </div>
            );
          } else {
            return (
              <Button
                color="primary"
                onClick={() => {
                  setDialogOpen(false);
                  setImportWasDone(false);
                  setSelectedFileToUpload(null);
                  setUploadingFile(false);
                  setErrorsArray([]);
                }}
              >
                OK
              </Button>
            );
          }
        }}
        title={"Import " + importType}
        size="lg"
      />
    </div>
  );
}
