import React, { useState, useRef, useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Copyright from "./../components/Copyright";
import Background from "./../img/test3.jpg";
import LoginIcon from "@material-ui/icons/ArrowRightAltRounded";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "./../components/Context";
import ReactCodeInput from "react-verification-code-input";
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import pathString from "./../get_php_link";
import CustomCountdown from "./../components/CustomCountdown";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  smsVerifContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
  },
  link: { textAlign: "center", marginTop: 20 },
}));

export default function SignInSide() {
  const classes = useStyles();
  let history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [signingIn, setSigningIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [credentialsAreOk, setCredentialsAreOk] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [verifyingCodeFailed, setVerifyingCodeFailed] = useState(false);
  const smsCodeRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackText, setSnackText] = useState(false);
  const goToRegisterScreen = () => {
    history.push("/register");
  };
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const userCheck = async (type, code) => {
    let params = new FormData();
    params.append("username", username);
    params.append("password", password);
    params.append("type", type);
    if (type == 1) {
      params.append("code", code);
      params.append("id_user", user.USER_ID);
    }
    let result = await axios
      .post(pathString + "auth/login.php", params)
      .then((response) => {
        setUser({
          ...user,
          ...response.data,
          VIEW_RIGHTS: response.data.VIEW_RIGHTS
            ? JSON.parse(response.data.VIEW_RIGHTS)
            : null,
          EDIT_RIGHTS: response.data.EDIT_RIGHTS
            ? JSON.parse(response.data.EDIT_RIGHTS)
            : null,
          name: username,
        });
        return response.data;
      })
      .catch(() => {
        setSigningIn(false);
        setCredentialsAreOk(false);
        return { USER_ID: null };
      });

    return result;
  };

  const handleLogIn = async () => {
    setSigningIn(true);
    let firstCheck = await userCheck(0, "");
    if (firstCheck.USER_ID) {
      setCredentialsAreOk(true);
    } else {
      if (firstCheck.IS_LOGGED == 0) {
        setSnackText(
          "Credențialele introduse sunt incorecte. Încercați din nou!"
        );
      } else {
        setSnackText(
          "Există o eroare la conexiunea cu serverul. Încercați mai târziu."
        );
      }
      handleSnackbarOpen();
    }
    setSigningIn(false);
  };

  const resendCode = () => {
    userCheck(0, "");
    setVerifyingCodeFailed(false);
  };

  const handleCodeInsertionCompleted = async (code) => {
    setVerifyingCode(true);
    let secondCheck = await userCheck(1, code);

    //   0: "Medic familie",
    //   1: "Contribuitor",
    //   2: "Admin",
    //   3: "Moderator",
    //   -1: "Dezactivat",

    if (secondCheck.ACCESS_LEVEL) {
      let accountType = secondCheck.ACCESS_LEVEL;
      if (accountType == 0) history.push("/search");
      else if (accountType == 1 || accountType == 2 || accountType == 3)
        history.push("/admin");
      else
        alert(
          "Contul dumneavoastră a fost dezactivat. Contactați DSP pentru mai multe detalii.\nTel.: 0256/494.680, 0256/494.682"
        );
    } else {
      setVerifyingCodeFailed(true);
      smsCodeRef.current.__clearvalues__();
    }

    setVerifyingCode(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // message="Credențialele introduse sunt incorecte, Încercați din nou!"
          // contentProps={{ style: { backgroundColor: "red", color: "white" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "red",
              width: 450,
              height: 50,
              textAlign: "center",
              borderRadius: 10,
              color: "white",
              marginBottom: 30,
            }}
          >
            {snackText}
          </div>
        </Snackbar>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autentificare
          </Typography>
          {/* <CustomCountyComponent /> */}
          <form className={classes.form} noValidate>
            {credentialsAreOk ? (
              <div className={classes.smsVerifContainer}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Codul de autentificare va fi trimis prin intermediul unui SMS
                  la {password}, acest cod fiind valabil timp de 10 minute.
                </Typography>
                <div style={{ marginTop: 15 }}>
                  <ReactCodeInput
                    ref={smsCodeRef}
                    type="text"
                    onComplete={handleCodeInsertionCompleted}
                    loading={verifyingCode}
                    autoFocus={true}
                  />
                </div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  style={{ marginTop: 15 }}
                >
                  <Link
                    onClick={resendCode}
                    color={verifyingCodeFailed ? "secondary" : "primary"}
                  >
                    {verifyingCodeFailed
                      ? "Codul introdus nu este corect, apăsați aici pentru a primi un cod nou."
                      : "Apăsați aici dacă nu ați primit SMS-ul."}
                  </Link>
                  <CustomCountdown />
                </Typography>
              </div>
            ) : (
              <>
                <TextField
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="nume"
                  label="Nume și prenume (conform C.I.)"
                  name="nume"
                  autoFocus
                />

                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Număr de telefon"
                  //   type="password"
                  id="phoneNr"
                  onKeyPress={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === "Enter") {
                      handleLogIn();
                      ev.preventDefault();
                    }
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={
                    signingIn || username.length == 0 || password.length == 0
                  }
                  startIcon={<LoginIcon />}
                  onClick={handleLogIn}
                >
                  {signingIn ? <CircularProgress size={20} /> : "Autentificare"}
                </Button>
              </>
            )}
          </form>
        </div>
        <Box mt={5} style={{ marginTop: "auto", marginBottom: 20 }}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
