import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import pathString from "./../../../get_php_link";

function CustomSaveComponent(props) {
  const {
    localitate,
    medicFamilie,
    nationalitate,
    stare,
    dataIntrare,
    // dataIesire,
  } = props;

  const formatDate = (strDate) => {
    if (strDate && strDate.includes(".")) {
      let arr = strDate.split(".");
      if (arr.length == 3) strDate = arr[2] + "-" + arr[1] + "-" + arr[0];
    } else if (strDate && strDate.includes("-")) {
      let arr = strDate.split("-");
      if (arr.length == 3) strDate = arr[2] + "-" + arr[1] + "-" + arr[0];
    }
    return strDate;
  };
  // console.log(dataIntrare);
  const handleSave = () => {
    let cnp = document.getElementById("cnp").value;
    // let dataIesire = document.getElementById("dataIesire").value;
    // let dataIntrare = document.getElementById("dataIntrare").value;
    let detaliiAdresa = document.getElementById("detaliiAdresa").value;
    let email = document.getElementById("email").value;
    // // let localitate = document.getElementById("localitate").value;
    // let medicFam = document.getElementById("medicFam").value;
    let nrStrada = document.getElementById("nrStrada").value;
    let nume = document.getElementById("nume").value;
    let strada = document.getElementById("strada").value;
    let telefon = document.getElementById("telefon").value;

    let params = new FormData();
    params.append("type", "contacti");
    params.append("numeSursa", props.numeSursa);
    params.append("prenumeSursa", props.prenumeSursa);
    params.append("cnpSursa", props.cnpSursa);
    params.append("codCazSursa", props.codCazSursa);
    params.append("CNP", cnp);
    // params.append("dataIesire", dataIesire);
    params.append("dataIntrare", dataIntrare);
    params.append("detaliiAdresa", detaliiAdresa);
    params.append("email", email);
    params.append("localitate", localitate);
    params.append("medic", medicFamilie);
    params.append("nrStrada", nrStrada);
    params.append("nume", nume);
    params.append("stare", stare);
    params.append("strada", strada);
    params.append("telefon", telefon);
    params.append("nationalitate", nationalitate);

    axios.post(pathString + "admin/insert.php", params).then(() => {
      props.doneOrCancel();
      props.getData();
    });
  };

  const handleCancel = () => {
    props.doneOrCancel();
  };
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
      <IconButton onClick={handleSave}>
        <DoneIcon style={{ color: "green" }} />
      </IconButton>

      <IconButton onClick={handleCancel}>
        <ClearIcon style={{ color: "red" }} />
      </IconButton>
    </div>
  );
}

export default CustomSaveComponent;
