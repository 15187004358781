import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Table, Input, Button, Popconfirm, Form } from "antd";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomDeleteComponent from "./../CommonComponents/CustomDeleteComponent";
import DownloadComponent from "./../CommonComponents/DownloadComponent";
// import CustomCityComponent from "../../CustomCityComponent";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import DropdownNationalitate from "./../CommonComponents/DropdownNationalitate";
import StateDropdown from "./../CommonComponents/StateDropdown";
import { Typography } from "@material-ui/core/";
import mystyle from "./mystyleContact.module.css";
import DateSelect from "./../CommonComponents/DateSelect";

const EditableContext = React.createContext();
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="editing"
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onDoubleClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class TabelContacti extends Component {
  handleSave = (row) => {
    let params = new FormData();
    params.append("type", "contacti");
    params.append("row", JSON.stringify(row));
    axios.post(pathString + "admin/update.php", params).then(() => {
      this.props.getData();
    });
  };

  handleDelete = (row) => {
    let params = new FormData();

    params.append("nrCaz", row["cod caz"]);
    params.append("type", "contacti");
    axios.post(pathString + "admin/delete.php", params).then(() => {
      this.props.getData();
    });
  };

  render() {
    const user = this.props.user;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    return (
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        columns={[
          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Descarcă",
                  render: (placeholder, record) => {
                    var searchText;
                    if (record.CNP && record.CNP != "") {
                      searchText = record.CNP;
                    } else searchText = record["Nume"];
                    if (
                      (record.CNP && record.CNP != "") ||
                      (record["Nume"] && record["Nume"] != "")
                    )
                      return (
                        <DownloadComponent
                          record={record}
                          searchText={searchText}
                        />
                      );
                    else return null;
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["cod caz"]
            ? [
                {
                  width: 200,
                  title: "Cod caz",
                  dataIndex: "cod caz",
                  render: (placeholder, record) =>
                    !record["cod caz suspensie"] ? (
                      <Typography
                        style={{ backgroundColor: "#ffff76", fontSize: "13px" }}
                      >
                        {record["cod caz"]}
                      </Typography>
                    ) : (
                      <Typography style={{ fontSize: "13px" }}>
                        {record["cod caz"]}
                      </Typography>
                    ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["cod caz"],
                    dataIndex: "cod caz",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["cod caz sursa"]
            ? [
                {
                  width: 250,
                  title: "Cod sursă",
                  dataIndex: "cod caz sursa",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["cod caz sursa"],
                    dataIndex: "cod caz sursa",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Nume Sursa"]
            ? [
                {
                  width: 250,
                  title: "Sursă",
                  dataIndex: "Nume Sursa",
                  render: (placeholder, record) =>
                    !record["cod caz suspensie"] ? (
                      <Typography style={{ fontSize: "14px" }}>
                        {record["Nume Sursa"]} - Sursă lipsă
                      </Typography>
                    ) : (
                      <Typography style={{ fontSize: "14px" }}>
                        {record["Nume Sursa"]}
                      </Typography>
                    ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Nume Sursa"],
                    dataIndex: "Nume Sursa",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Nume"]
            ? [
                {
                  width: 250,
                  title: "Nume",
                  dataIndex: "Nume",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["contacti"]["Nume"],
                    dataIndex: "Nume",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Telefon"]
            ? [
                {
                  width: 130,
                  title: "Telefon",
                  dataIndex: "Telefon",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Telefon"],
                    dataIndex: "Telefon",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Localitate"]
            ? [
                {
                  width: 200,
                  title: "Localitate",
                  dataIndex: "Localitate",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["Localitate"]
                      )
                    )
                      return <span>{record.Localitate}</span>;
                    else
                      return (
                        <CustomCityComponent
                          onChange={(Localitate) => {
                            this.handleSave({
                              ...record,
                              Localitate: Localitate,
                            });
                          }}
                          oldValue={record.Localitate}
                          selectedCounty=""
                        />
                      );
                  },
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Strada"]
            ? [
                {
                  width: 250,
                  title: "Strada",
                  dataIndex: "Strada",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Strada"],
                    dataIndex: "Strada",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Nr. Strada"]
            ? [
                {
                  width: 130,
                  title: "Nr. stradă",
                  dataIndex: "Nr. Strada",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Nr. Strada"],
                    dataIndex: "Nr. Strada",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Detalii adresa"]
            ? [
                {
                  width: 130,
                  title: "Detalii adresă",
                  dataIndex: "Detalii adresa",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Detalii adresa"],
                    dataIndex: "Detalii adresa",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Data intrare"]
            ? [
                {
                  width: 180,
                  title: "Data intrare",
                  dataIndex: "Data intrare",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["Data intrare"]
                      }
                      width={150}
                      initialValue={record["Data intrare"]}
                      onChange={(date) =>
                        this.handleSave({
                          ...record,
                          "Data intrare": date,
                        })
                      }
                    />
                  ),
                  // render: (placeholder, record) => (
                  //   <span>{record["Data intrare"]}</span>
                  // ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Data intrare"],
                    dataIndex: "Data intrare",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["CNP"]
            ? [
                {
                  width: 140,
                  title: "CNP",
                  dataIndex: "CNP",
                  render: (placeholder, record) =>
                    record["CNP"] &&
                    record["CNP"].length == 13 &&
                    parseInt(record["CNP"][0]) != 0 &&
                    parseInt(record["CNP"][0]) != 9 &&
                    parseInt(record["CNP"].substring(3, 5)) <= 12 &&
                    parseInt(record["CNP"].substring(5, 7)) <= 31 ? (
                      <Typography>{record["CNP"]}</Typography>
                    ) : (
                      <Typography style={{ backgroundColor: "#ffff76" }}>
                        {record["CNP"]}
                      </Typography>
                    ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["contacti"]["CNP"],
                    dataIndex: "CNP",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Data iesire"]
            ? [
                {
                  width: 180,
                  title: "Data ieșire",
                  dataIndex: "Data iesire",
                  render: (placeholder, record) => (
                    <DateSelect
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["Data iesire"]
                      }
                      width={150}
                      initialValue={record["Data iesire"]}
                      onChange={(date) =>
                        this.handleSave({
                          ...record,
                          "Data iesire": date,
                        })
                      }
                    />
                  ),
                  // render: (placeholder, record) => (
                  //   <span>{record["Data iesire"]}</span>
                  // ),
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Data iesire"],
                    dataIndex: "Data iesire",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Stare"]
            ? [
                {
                  width: 250,
                  title: "Stare",
                  render: (placeholder, record) => (
                    <StateDropdown
                      stareVeche={record["Stare"]}
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["Stare"]
                      }
                      onChange={(stare) =>
                        this.handleSave({ ...record, Stare: stare })
                      }
                    />
                  ),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Nr. Decizie"]
            ? [
                {
                  width: 130,
                  title: "Nr. Decizie",
                  dataIndex: "Nr. Decizie",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["Nr. Decizie"],
                    dataIndex: "Nr. Decizie",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Medic Familie"]
            ? [
                {
                  width: 300,
                  title: "Medic Familie",
                  dataIndex: "Medic Familie",
                  render: (placeholder, record) => {
                    if (
                      !(
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["Medic Familie"]
                      )
                    )
                      return <span>{record["Medic Familie"]}</span>;
                    else
                      return (
                        <MedicSelectComponent
                          onChange={(medicFam) => {
                            this.handleSave({
                              ...record,
                              "Medic Familie": medicFam,
                            });
                          }}
                          oldValue={record["Medic Familie"]}
                        />
                      );
                  },
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["Email"]
            ? [
                {
                  width: 200,
                  title: "Email",
                  dataIndex: "Email",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS && user.EDIT_RIGHTS["contacti"]["Email"],
                    dataIndex: "Email",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),
          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["nationalitate"]
            ? [
                {
                  width: 130,
                  title: "Naționalitate",
                  dataIndex: "nationalitate",
                  sorter: (a, b) =>
                    a["nationalitate"].localeCompare(b["nationalitate"]),
                  render: (placeholder, record) => (
                    <DropdownNationalitate
                      nationalitateVeche={record["nationalitate"]}
                      editable={
                        user.EDIT_RIGHTS &&
                        user.EDIT_RIGHTS["contacti"]["nationalitate"]
                      }
                      onChange={(nationalitate) =>
                        this.handleSave({
                          ...record,
                          nationalitate: nationalitate,
                        })
                      }
                    />
                  ),
                },
              ]
            : []),

          ...(user.VIEW_RIGHTS && user.VIEW_RIGHTS["contacti"]["comentarii"]
            ? [
                {
                  width: 250,
                  title: "Comentarii",
                  dataIndex: "comentarii",
                  onCell: (record) => ({
                    record,
                    editable:
                      user.EDIT_RIGHTS &&
                      user.EDIT_RIGHTS["contacti"]["comentarii"],
                    dataIndex: "comentarii",
                    handleSave: this.handleSave,
                  }),
                },
              ]
            : []),

          ...(user.ACCESS_LEVEL == 2 || user.ACCESS_LEVEL == 3
            ? [
                {
                  width: 90,
                  title: "Șterge",
                  render: (placeholder, record) => {
                    return (
                      <CustomDeleteComponent
                        handleDelete={() => this.handleDelete(record)}
                      />
                    );
                  },
                },
              ]
            : []),
        ]}
        bordered
        rowKey={(record) => record["cod caz"]}
        loading={this.props.loading}
        dataSource={this.props.tableData}
        pagination={false}
        size="small"
        scroll={{ y: "calc(100vh - 180px)" }}
      />
    );
  }
}
