const columnMapping = {
  "cod caz": "Cod caz",
  spital: "Spital/DSP",
  laborator: "Laborator",
  "nr proba": "Nr. proba",
  nume: "Nume",
  prenume: "Prenume",
  cnp: "CNP",
  varsta: "Vârstă",
  personal: "Personal medico-sanitar",
  "data debut": "Data debut",
  "tip proba": "Tip proba",
  "data recoltare spalatura": "Data recoltare spălătură sau aspirat nazal",
  "data recoltare exsudat nazal": "Data recoltare exsudat nazal",
  "data recoltare exsudat nasofaringian":
    "Data recoltare exsudat nasofaringian",
  "data recoltare sputa": "Data recoltare sputa",
  "data recoltare aspirat traheo bronsic":
    "data recoltare aspirat traheo bronșic",
  "data recoltare produs de lavaj": "Data produs de lavaj",
  "data recoltare fragmente necroptice": "Data recoltare fragmente necroptice",
  "data recoltare alta proba": "Data recoltare altă probă",
  "data trimiterii probei": "Data trimiterii probei",
  "medic trimitator": "Medic trimițător",

  "nr. crt.": "Nr. crt.",
  "status ancheta": "Status ancheta",
  "responsabil ancheta": "Responsabil ancheta",
  stare: "Stare",
  nume: "Nume",
  prenume: "Prenume",
  debutul_bolii: "Debutul bolii",
  judet_infectare: "Judet infectare",
  localitate_infectare: "Localitate infectare",
  siruta_localitate_infectare: "Siruta",
  cnp: "CNP",
  data_nastere: "Data nasterii",
  varsta: "Varsta",
  data_recoltarii: "Data recoltarii",
  data_confirmarii: "Data confirmarii",
  nr_confirmat: "Nr. confirmat",
  data_internarii: "Data internare",
  data_externarii: "Data externare",
  data_decedat: "Data decesului",
  nr_decedat: "Nr. decedat",
  "organ control": "Organ control",
  "institutie organ control": "Institutie organ control",
  spital: "Spital",
  nr_pacient: "Nr. pacient",
  tratament: "Tratament",
  teste: "Teste",
  laborator: "Laborator",
  "motiv carantinare": "Motiv carantinare",
  "recomandare dr": "Recomandare doctor",
  "simptome clinice": "Simptome clinice",
  "nr test pcr": "Nr. test PCR",
  "tip decizie": "Tip decizie",
  "medic familie": "Medic familie",
  sex: "Sex",
  email: "Email",
  nr_telefon: "Nr. telefon",
  "serie ci": "Serie CI",
  "numar ci": "Nr. CI",
  "ci eliberat": "CI eliberat",
  "data eliberare ci": "Data eliberare CI",
  cetatenie: "Cetatenie",
  judet_domiciliu: "Judet domiciuliu",
  adresa: "Adresa",
  adresa_localitate: "Localitate",
  adresa_strada: "Strada",
  adresa_nr: "Nr.",
  adresa_detalii: "Detalii",
  ocupatie: "Ocupatie",
  "Loc de munca": "Loc de munca",
  "judet adresa reala": "Judet (adresa reala)",
  "localitate adresa reala": "Localitate (adresa reala)",
  "strada adresa reala": "Strada (adresa reala)",
  "nr adresa reala": "Nr. (adresa reala)",
  "detalii adresa reala": "Detalii (adresa reala)",
  "judet adresa izolare": "Judet (adresa reala)",
  "localitate adresa izolare": "Localitate (adresa izolare)",
  "strada adresa izolare": "Strada (adresa izolare)",
  "nr adresa izolare": "Nr. (adresa izolare)",
  "detalii adresa izolare": "Detalii (adresa izolare)",
  link_epidemiologic: "Link epidemiologic",
  comentarii: "Comentarii",
  data_creare: "Data creare",
  "Data import": "Data import",

  // "nr. crt.": "Numar criteriu",
  // "cod caz": "Cod caz",
  // "nume prenume": "Nume",
  // "data nasteri": "Data nasterii",
  // varsta: "Varsta",
  // sex: "Sex",
  // nationalitate: "Naționalitate",
  // email: "Email",
  // cnp: "CNP",
  // "serie ci": "Serie CI",
  // "numar ci": "Numar CI",
  // "ci eliberat": "CI eliberat",
  // "data eliberare ci ": "Data eliberare CI",
  // "judet adresa buletin": "Județ (adresa din buletin)",
  // "judet adresa reala": "Județ (adresa reală)",
  // "judet adresa izolare": "Județ (adresa de izolare)",
  // "judet adresa activitate": "Județ (adresa locului de muncă)",
  // "localitate adresa buletin": "Localitate (adresa din buletin)",
  // "localitate adresa reala": "Localitate (adresa reală)",
  // "localitate adresa izolare": "Localitate (adresa de izolare)",
  // "localitate adresa activitate": "Localitate (adresa locului de muncă)",
  // "strada adresa buletin": "Strada (adresa din buletin)",
  // "strada adresa reala": "Strada (adresa reală)",
  // "strada adresa izolare": "Strada (adresa de izolare)",
  // "strada adresa activitate": "Strada (adresa locului de muncă)",
  // "nr adresa buletin": "Nr. (adresa din buletin)",
  // "nr adresa reala": "Nr. (adresa reală)",
  // "nr adresa izolare": "Nr. (adresa de izolare)",
  // "nr adresa activitate": "Nr. (adresa locului de muncă)",
  // "detalii adresa buletin": "Detalii (adresa din buletin)",
  // "detalii adresa reala": "Detalii (adresa reală)",
  // "detalii adresa izolare": "Detalii (adresa de izolare)",
  // "detalii adresa activitate": "Detalii (adresa locului de muncă)",
  // "motiv carantinare": "Motiv carantinare",
  // "recomandare dr": "Recomandare doctor",
  // "oragan control": "Organ de control",
  // "institutie organ control": "Institutie organ de control",
  // "simptome clinice": "Simptome clinice",
  // "nr test pcr": "Numar test PCR",
  // "data test pcr": "Data test PCR",
  // "numar caz": "Numar caz",
  // "tip decizie": "Tip decizie",
  // telefon: "Telefon",
  // "medic familie": "Medic de familie",
  // "data debut": "Data debut",
  // "data internare": "Data internare",
  // "data rezultat": "Data rezultat",
  // "data deces": "Data deces",
  // "data externare": "Data externare",
  // stare: "Stare",
  // "Cadru medical": "Cadru medical",
  // "Loc de munca": "Loc de munca",
  // "link epidemiologic": "Link epidemiologic",
  // "status ancheta": "Status ancheta",
  // "responsabil ancheta": "Responsabil ancheta",

  "Nume Sursa": "Nume sursa",
  "CNP Sursa": "CNP sursa",
  Nume: "Nume",
  Telefon: "Telefon",
  Email: "Email",
  nationalitate: "Naționalitate",
  "Medic Familie": "Medic de familie",
  Localitate: "Localitate",
  Strada: "Strada",
  "Nr. Strada": "Nr. Strada",
  "Detalii adresa": "Detalii adresa",
  "Data intrare": "Data intrare",
  CNP: "CNP",
  "Data iesire": "Data iesire",
  "cod caz sursa": "Cod caz sursa",
  Stare: "Stare",
  NUME: "Nume",
  NUME_CABINET: "Nume cabinet",
  ADRESA: "Adresa",
  LOCALITATE: "Localitate",
  PAROLA: "Numar telefon",
  EMAIL: "Email",
  TYPE: "Tip",
};

const Counties = [
  { auto: "TM", nume: "Timis" },
  { auto: "AB", nume: "Alba" },
  { auto: "AR", nume: "Arad" },
  { auto: "AG", nume: "Arges" },
  { auto: "BC", nume: "Bacau" },
  { auto: "BH", nume: "Bihor" },
  { auto: "BN", nume: "Bistrita-Nasaud" },
  { auto: "BT", nume: "Botosani" },
  { auto: "BR", nume: "Braila" },
  { auto: "BV", nume: "Brasov" },
  { auto: "B", nume: "Bucuresti" },
  { auto: "BZ", nume: "Buzau" },
  { auto: "CL", nume: "Calarasi" },
  { auto: "CS", nume: "Caras-Severin" },
  { auto: "CJ", nume: "Cluj" },
  { auto: "CT", nume: "Constanta" },
  { auto: "CV", nume: "Covasna" },
  { auto: "DB", nume: "Dambovita" },
  { auto: "DJ", nume: "Dolj" },
  { auto: "GL", nume: "Galati" },
  { auto: "GR", nume: "Giurgiu" },
  { auto: "GJ", nume: "Gorj" },
  { auto: "HR", nume: "Harghita" },
  { auto: "HD", nume: "Hunedoara" },
  { auto: "IL", nume: "Ialomita" },
  { auto: "IS", nume: "Iasi" },
  { auto: "IF", nume: "Ilfov" },
  { auto: "MM", nume: "Maramures" },
  { auto: "MH", nume: "Mehedinti" },
  { auto: "MS", nume: "Mures" },
  { auto: "NT", nume: "Neamt" },
  { auto: "OT", nume: "Olt" },
  { auto: "PH", nume: "Prahova" },
  { auto: "SJ", nume: "Salaj" },
  { auto: "SM", nume: "Satu Mare" },
  { auto: "SB", nume: "Sibiu" },
  { auto: "SV", nume: "Suceava" },
  { auto: "TR", nume: "Teleorman" },
  { auto: "TL", nume: "Tulcea" },
  { auto: "VL", nume: "Valcea" },
  { auto: "VS", nume: "Vaslui" },
  { auto: "VN", nume: "Vrancea" },
];

export { Counties };
export default columnMapping;
