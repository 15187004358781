import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, TextField, Snackbar } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
// import mystyle from "./../mystyle.module.css";
import mystyle from "./mystyleContact.module.css";

import DateSelect from "./../CommonComponents/DateSelect";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StateDropdown from "./../CommonComponents/StateDropdown";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "80vw",
    height: "75vh",
  },
  details: {
    padding: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

export default function ModalAddContact(props) {
  const [codCazSursa, setCodCazSursa] = React.useState("");
  const [cnpSursa, setCnpSursa] = React.useState("");
  const [numeSursa, setNumeSursa] = React.useState("");
  const [nume, setNume] = React.useState("");
  const [telefon, setTelefon] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [medic, setMedic] = React.useState("");
  const [localitate, setLocalitate] = React.useState("");
  const [strada, setStrada] = React.useState("");
  const [nrStrada, setNrStrada] = React.useState("");
  const [detaliiAdresa, setDetaliiAdresa] = React.useState("");
  const [dataIntrare, setDataIntrare] = React.useState(null);
  const [CNP, setCNP] = React.useState("");
  const [dataIesire, setDataIesire] = React.useState(null);
  const [stare, setStare] = React.useState("");
  const [nrDecizie, setNrDecizie] = React.useState("");
  const [nationalitate, setNationalitate] = React.useState("");
  const [comentarii, setComentarii] = React.useState("");

  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [requiredTextColor, setRequiredTextColor] = React.useState("black");
  const [snackbarText, setSnackbarText] = React.useState(
    "Vă rugăm să completați toate câmpurile necesare!"
  );
  const reset = () => {
    setCodCazSursa("");
    setCnpSursa("");
    setNumeSursa("");
    setNume("");
    setTelefon("");
    setEmail("");
    setMedic("");
    setLocalitate("");
    setStrada("");
    setNrStrada("");
    setDetaliiAdresa("");
    setDataIntrare(null);
    setCNP("");
    setDataIesire(null);
    setNationalitate("");
    setComentarii("");
    setRequiredTextColor("black");
    setSnackbarText("Vă rugăm să completați toate câmpurile necesare!");
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const adauga = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    if (codCazSursa == "" || nume == "" || telefon == "") {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setSnackbarText("Vă rugăm să completați toate câmpurile necesare!");
    } else if (dataIntrare > today) {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setSnackbarText("Data de intrare este mai mare decât cea de azi!");
    } else if (dataIntrare && dataIesire && dataIntrare > dataIesire) {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setSnackbarText("Data de intrare este mai mare decât cea de ieșire!");
    } else {
      let params = new FormData();
      params.append("type", "contacti");
      if (codCazSursa != "") {
        params.append("codCazSursa", codCazSursa);
      }
      if (cnpSursa != "") {
        params.append("cnpSursa", cnpSursa);
      }
      if (numeSursa != "") {
        params.append("numeSursa", numeSursa);
      }
      if (nume != "") {
        params.append("nume", nume);
      }
      if (telefon != "") {
        params.append("telefon", telefon);
      }
      if (email != "") {
        params.append("email", email);
      }
      if (medic != "") {
        params.append("medic", medic);
      }
      if (localitate != "") {
        params.append("localitate", localitate);
      }
      if (strada != "") {
        params.append("strada", strada);
      }
      if (nrStrada != "") {
        params.append("nrStrada", nrStrada);
      }
      if (detaliiAdresa != "") {
        params.append("detaliiAdresa", detaliiAdresa);
      }
      if (dataIntrare != "") {
        params.append("dataIntrare", dataIntrare);
      }
      if (CNP != "") {
        params.append("CNP", CNP);
      }
      if (dataIesire != "") {
        params.append("dataIesire", dataIesire);
      }
      if (nationalitate != "") {
        params.append("nationalitate", nationalitate);
      }
      if (comentarii != "") {
        params.append("comentarii", comentarii);
      }
      if (stare != "") {
        params.append("stare", stare);
      }

      axios
        .post(pathString + "admin/insert.php", params)
        .then(() => {
          props.getData();
          props.hideModal();
          reset();
        })
        .catch((e) => {
          reset();
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={() => {
        props.hideModal();
        reset();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Adăugare contact
        </h2>
        <div className={classes.details}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                width: 450,
                height: 50,
                textAlign: "center",
                borderRadius: 10,
                color: "white",
                marginBottom: 30,
              }}
            >
              {snackbarText}
            </div>
          </Snackbar>
          <div className={mystyle.paper}>
            <div className={mystyle.firstColumn}>
              <div className={mystyle.label}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Cod caz sursă*:{" "}
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setCodCazSursa(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Nume sursă*:{" "}
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNumeSursa(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Nume*:{" "}
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNume(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Telefon*:{" "}
                </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setTelefon(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Localitate: </label>
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    <CustomCityComponent
                      onChange={(localitate) => {
                        setLocalitate(localitate);
                      }}
                      oldValue={localitate}
                      selectedCounty=""
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Strada: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setStrada(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Nr strada: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNrStrada(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Detalii adresa: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setDetaliiAdresa(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={mystyle.secondColumn}>
              <div className={mystyle.labelDate}>
                <label alignItems="left">Data intrării: </label>
                <div className={mystyle.value}>
                  <DateSelect
                    editale={true}
                    onChange={(date) => setDataIntrare(date)}
                  />
                </div>
              </div>
              <div className={mystyle.label}>
                <label alignItems="left">CNP: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setCNP(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.labelDate}>
                <label alignItems="left">Data ieșirii: </label>
                <div className={mystyle.value}>
                  <DateSelect
                    editale={true}
                    onChange={(date) => setDataIesire(date)}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Stare: </label>
                <div className={mystyle.value}>
                  <StateDropdown
                    style={{ width: 280 }}
                    // stareVeche="Carantinat"
                    onChange={(stareDrop) => {
                      setStare(stareDrop);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Nr. decizie: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNrDecizie(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Medic de familie: </label>
                <div className={mystyle.value}>
                  <div style={{ width: 280 }}>
                    <MedicSelectComponent
                      onChange={(medicFam) => {
                        setMedic(medicFam);
                      }}
                      oldValue={medic}
                    />
                  </div>
                </div>
              </div>

              <div className={mystyle.label}>
                <label alignItems="left">Email: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              {/* <div className={mystyle.label}>
                <label alignItems="left">Numar caz: </label>
                <div className={mystyle.value}>
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNrCaz(event.target.value);
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <div>
              <button
                className={mystyle.cancelButton}
                onClick={() => {
                  props.hideModal();
                  reset();
                }}
              >
                Anulare
              </button>
            </div>
            <div
              style={{ flex: 1, display: "flex", flexDirection: "row-reverse" }}
            >
              <button className={mystyle.addButtonModal} onClick={adauga}>
                Adăugare
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
