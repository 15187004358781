import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, TextField, Snackbar } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import mystyle from "./../mystyle.module.css";
import DateSelect from "./../CommonComponents/DateSelect";
import axios from "axios";
import pathString from "./../../../get_php_link";
import CustomCountyComponent from "./../CommonComponents/CustomCountyComponent";
import CustomCityComponent from "./../CommonComponents/CustomCityComponent";
import MedicSelectComponent from "./../CommonComponents/MedicSelectComponent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    width: "95%",
    height: "90%",
  },
  details: {
    padding: 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    padding: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
}));

export default function ModalAddSuspecti(props) {
  const [spital, setSpital] = React.useState("");
  const [laborator, setLaborator] = React.useState("");
  const [codCaz, setCodCaz] = React.useState("");
  const [nrProba, setNrProba] = React.useState("");
  const [nume, setNume] = React.useState("");
  const [prenume, setPrenume] = React.useState("");
  const [CNP, setCNP] = React.useState("");
  const [varsta, setVarsta] = React.useState("");
  const [personal, setPersonal] = React.useState("");
  const [dataDebut, setDataDebut] = React.useState("");
  const [tipProba, setTipProba] = React.useState([]);
  const [dataTrimiteriiProbei, setDataTrimiteriiProbei] = React.useState("");
  const [medicTrimitator, setMedicTrimitator] = React.useState("");
  const [dataAspiratNazal, setDataAspiratNazal] = React.useState("");
  const [dataExsudatNazal, setDataExsudatNazal] = React.useState("");

  const [
    dataExsudatNasofaringian,
    setDataExsudatNasofaringian,
  ] = React.useState("");

  const [dataSputa, setDataSputa] = React.useState("");

  const [
    dataAspiratTraheoBronsic,
    setDataAspiratTraheoBronsic,
  ] = React.useState("");

  const [dataLavaj, setDataLavaj] = React.useState("");
  const [specificatieAltaProba, setSpecificatieAltaProba] = React.useState("");

  const [dataAltTip, setDataAltTip] = React.useState("");

  const [dataFragmenteNecroptice, setDataFragmenteNecroptice] = React.useState(
    ""
  );

  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [requiredTextColor, setRequiredTextColor] = React.useState("black");
  const [snackbarText, setSnackbarText] = React.useState(
    "Vă rugăm să completați toate câmpurile necesare!"
  );
  const reset = () => {
    setDataAspiratNazal("");
    setDataExsudatNazal("");
    setDataExsudatNasofaringian("");
    setDataSputa("");
    setDataAspiratTraheoBronsic("");
    setDataLavaj("");
    setSpecificatieAltaProba("");
    setDataAltTip("");
    setDataFragmenteNecroptice("");
    setSpital("");
    setLaborator("");
    setCodCaz("");
    setNrProba("");
    setPersonal("");
    setNume("");
    setPrenume("");
    setCNP("");
    setVarsta("");
    setDataDebut("");
    setTipProba([]);
    setDataTrimiteriiProbei("");
    setMedicTrimitator("");
    setRequiredTextColor("black");
    setSnackbarText("Vă rugăm să completați toate câmpurile necesare!");
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const adaugaSiExporta = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    if (nume == "" || prenume == "" || dataDebut == "") {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setSnackbarText("Vă rugăm să completați toate câmpurile necesare!");
    } else {
      let params = new FormData();
      params.append("type", "suspecti");
      if (spital != "") {
        params.append("spital", spital);
      }
      if (laborator != "") {
        params.append("laborator", laborator);
      }
      if (nrProba != "") {
        params.append("nrProba", nrProba);
      }
      if (nume != "") {
        params.append("nume", nume);
      }
      if (prenume != "") {
        params.append("prenume", prenume);
      }
      if (CNP != "") {
        params.append("CNP", CNP);
      }
      if (varsta != "") {
        params.append("varsta", varsta);
      }
      if (dataDebut != "") {
        params.append("dataDebut", dataDebut);
      }
      if (tipProba != []) {
        params.append("tipProba", tipProba);
      }
      if (dataTrimiteriiProbei != "") {
        params.append("dataTrimiteriiProbei", dataTrimiteriiProbei);
      }

      if (medicTrimitator != "") {
        params.append("medicTrimitator", medicTrimitator);
      }
      if (personal != "") {
        params.append("personal", personal);
      }

      if (dataAspiratNazal != "") {
        params.append("dataAspiratNazal", dataAspiratNazal);
      }
      if (dataExsudatNazal != "") {
        params.append("dataExsudatNazal", dataExsudatNazal);
      }
      if (dataExsudatNasofaringian != "") {
        params.append("dataExsudatNasofaringian", dataExsudatNasofaringian);
      }
      if (dataSputa != "") {
        params.append("dataSputa", dataSputa);
      }
      if (dataAspiratTraheoBronsic != "") {
        params.append("dataAspiratTraheoBronsic", dataAspiratTraheoBronsic);
      }
      if (dataLavaj != "") {
        params.append("dataLavaj", dataLavaj);
      }
      if (specificatieAltaProba != "") {
        params.append("specificatieAltaProba", specificatieAltaProba);
      }
      if (dataAltTip != "") {
        params.append("dataAltTip", dataAltTip);
      }
      if (dataFragmenteNecroptice != "") {
        params.append("dataFragmenteNecroptice", dataFragmenteNecroptice);
      }

      axios
        .post(pathString + "admin/insert.php", params)
        .then((response) => {
          props.getData();
          props.hideModal();
          reset();
          window.open(
            pathString + "helpers/exportSuspect.php?search=" + response.data
          );
        })
        .catch((e) => {
          reset();
        });
    }
  };

  const adauga = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    if (nume == "" || prenume == "" || dataDebut == "") {
      handleSnackbarOpen();
      setRequiredTextColor("red");
      setSnackbarText("Vă rugăm să completați toate câmpurile necesare!");
    } else {
      let params = new FormData();
      params.append("type", "suspecti");
      if (spital != "") {
        params.append("spital", spital);
      }
      if (laborator != "") {
        params.append("laborator", laborator);
      }
      if (nrProba != "") {
        params.append("nrProba", nrProba);
      }
      if (nume != "") {
        params.append("nume", nume);
      }
      if (prenume != "") {
        params.append("prenume", prenume);
      }
      if (CNP != "") {
        params.append("CNP", CNP);
      }
      if (varsta != "") {
        params.append("varsta", varsta);
      }
      if (dataDebut != "") {
        params.append("dataDebut", dataDebut);
      }
      if (tipProba != []) {
        params.append("tipProba", tipProba);
      }
      if (dataTrimiteriiProbei != "") {
        params.append("dataTrimiteriiProbei", dataTrimiteriiProbei);
      }

      if (medicTrimitator != "") {
        params.append("medicTrimitator", medicTrimitator);
      }
      if (personal != "") {
        params.append("personal", personal);
      }

      if (dataAspiratNazal != "") {
        params.append("dataAspiratNazal", dataAspiratNazal);
      }
      if (dataExsudatNazal != "") {
        params.append("dataExsudatNazal", dataExsudatNazal);
      }
      if (dataExsudatNasofaringian != "") {
        params.append("dataExsudatNasofaringian", dataExsudatNasofaringian);
      }
      if (dataSputa != "") {
        params.append("dataSputa", dataSputa);
      }
      if (dataAspiratTraheoBronsic != "") {
        params.append("dataAspiratTraheoBronsic", dataAspiratTraheoBronsic);
      }
      if (dataLavaj != "") {
        params.append("dataLavaj", dataLavaj);
      }
      if (specificatieAltaProba != "") {
        params.append("specificatieAltaProba", specificatieAltaProba);
      }
      if (dataAltTip != "") {
        params.append("dataAltTip", dataAltTip);
      }
      if (dataFragmenteNecroptice != "") {
        params.append("dataFragmenteNecroptice", dataFragmenteNecroptice);
      }

      axios
        .post(pathString + "admin/insert.php", params)
        .then((response) => {
          props.getData();
          props.hideModal();
          reset();
        })
        .catch((e) => {
          reset();
        });
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={props.isModalVisible || false}
      onClose={() => {
        props.hideModal();
        reset();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title" className={mystyle.headerModal}>
          Adăugare suspect
        </h2>
        <div className={classes.details}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                width: 450,
                height: 50,
                textAlign: "center",
                borderRadius: 10,
                color: "white",
                marginBottom: 30,
              }}
            >
              {snackbarText}
            </div>
          </Snackbar>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "40%",
                height: "70vh",
                display: "flex",
                marginRight: 10,
                marginLeft: 20,
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Nume:{" "}
                </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNume(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Prenume:{" "}
                </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setPrenume(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Spital/DSP: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setSpital(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Catre, laboratorul: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setLaborator(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Nr. probă: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setNrProba(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">CNP: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setCNP(event.target.value);
                    }}
                  />
                </div>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Vârstă: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setVarsta(event.target.value);
                    }}
                  />
                </div>
              </div> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Medic trimițător: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextField
                    alignItems="right"
                    style={{ width: 280 }}
                    id="standard-basic"
                    onChange={(event) => {
                      setMedicTrimitator(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Personal medico/sanitar: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={personal == "Da"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setPersonal("Da");
                          } else {
                            setPersonal("");
                          }
                        }}
                      />
                    }
                    label="Da"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        checked={personal == "Nu"}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setPersonal("Nu");
                          } else {
                            setPersonal("");
                          }
                        }}
                      />
                    }
                    label="Nu"
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left" style={{ color: requiredTextColor }}>
                  Data debut:{" "}
                </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                    paddingTop: 5,
                  }}
                >
                  <DateSelect onChange={(date) => setDataDebut(date._i)} />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label alignItems="left">Data trimiterii probei: </label>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                    paddingTop: 5,
                  }}
                >
                  <DateSelect
                    onChange={(date) => setDataTrimiteriiProbei(date._i)}
                  />
                </div>
              </div>
            </div>
            {/*//////////////////////////////// dreapta */}
            <div
              style={{
                width: "60%",
                height: "50vh",
                display: "flex",
                marginRight: 10,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  padding: 5,
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  <label alignItems="left">
                    Tipul probei și data recoltării
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "spalatura sau aspirat nazal"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              // setTipProba("spalatura sau aspirat nazal");
                              setTipProba((prevState) => [
                                ...prevState,
                                "spalatura sau aspirat nazal",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter(
                                  (e) => e != "spalatura sau aspirat nazal"
                                )
                              );
                            }
                          }}
                        />
                      }
                      label="spalatura sau aspirat nazal"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // flexDirection: "row-reverse",
                    }}
                  >
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect
                        onChange={(date) => setDataAspiratNazal(date._i)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "exsudat nazal"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "exsudat nazal",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter((e) => e != "exsudat nazal")
                              );
                            }
                            //   setTipProba("exsudat nazal");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="exsudat nazal/faringian"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect
                        onChange={(date) => setDataExsudatNazal(date._i)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "exsudat nasofaringian"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "exsudat nasofaringian",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter(
                                  (e) => e != "exsudat nasofaringian"
                                )
                              );
                            }
                            //   setTipProba("exsudat nasofaringian");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="exsudat nasofaringian"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",

                        paddingTop: 5,
                      }}
                    >
                      <DateSelect
                        onChange={(date) =>
                          setDataExsudatNasofaringian(date._i)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "sputa"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "sputa",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter((e) => e != "sputa")
                              );
                            }
                            //   setTipProba("sputa");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="sputa"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",

                        paddingTop: 5,
                      }}
                    >
                      <DateSelect onChange={(date) => setDataSputa(date._i)} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "aspirat traheo-bronsic"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "aspirat traheo-bronsic",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter(
                                  (e) => e != "aspirat traheo-bronsic"
                                )
                              );
                            }
                            //   setTipProba("aspirat traheo-bronsic");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="aspirat traheo-bronsic"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect
                        onChange={(date) =>
                          setDataAspiratTraheoBronsic(date._i)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={
                          //   tipProba == "produs de lavaj bronho-alveolar"
                          // }
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "produs de lavaj bronho-alveolar",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter(
                                  (e) => e != "produs de lavaj bronho-alveolar"
                                )
                              );
                            }
                            //   setTipProba("produs de lavaj bronho-alveolar");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="produs de lavaj bronho-alveolar"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării:</label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect onChange={(date) => setDataLavaj(date._i)} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          color="primary"
                          // checked={tipProba == "fragment necroptic de pulmon"}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setTipProba((prevState) => [
                                ...prevState,
                                "fragment necroptic de pulmon",
                              ]);
                            } else {
                              setTipProba((prevState) =>
                                prevState.filter(
                                  (e) => e != "fragment necroptic de pulmon"
                                )
                              );
                            }
                            //   setTipProba("fragment necroptic de pulmon");
                            // } else {
                            //   setTipProba("");
                            // }
                          }}
                        />
                      }
                      label="fragment necroptic de pulmon"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label alignItems="left">Data recoltării:</label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect
                        onChange={(date) => setDataFragmenteNecroptice(date._i)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25vw" }}>
                    <TextField
                      alignItems="right"
                      style={{ width: 280 }}
                      id="standard-basic"
                      placeholder="alt tip de proba"
                      onBlur={(event) => {
                        setTipProba((prevState) => [
                          ...prevState,
                          event.target.value,
                        ]);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label alignItems="left">Data recoltării: </label>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        // flexDirection: "row-reverse",
                        paddingTop: 5,
                      }}
                    >
                      <DateSelect onChange={(date) => setDataAltTip(date._i)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              marginBottom: 5,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <div>
              <button
                className={mystyle.cancelButton}
                onClick={() => {
                  props.hideModal();
                  reset();
                }}
              >
                Anulare
              </button>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button
                className={mystyle.addExportButtonModal}
                onClick={adaugaSiExporta}
              >
                Adăugare + export
              </button>
              <button className={mystyle.addButtonModal} onClick={adauga}>
                Adăugare
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
