import React from "react";
// import { Bar } from "react-chartjs-2";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import pathString from "./../../../get_php_link";
import mystyle from "./../mystyle.module.css";
import TabelIstoric from "./TabelIstoric";

class CustomDateIstoric extends React.Component {
  constructor() {
    super();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 3);
    let endDate = new Date();
    this.state = {
      startDate: startDate,
      endDate: endDate,
      tableData: [],
    };
  }

  handleDateChange = (name, date) => {
    if (name === "startDate") {
      var startDate = date;
      this.setState({ startDate });
    } else if (name === "endDate") {
      var endDate = date;
      this.setState({ endDate });
    }
  };
  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };

  getData = () => {
    let params = new FormData();
    var startDateString = this.formatDate(this.state.startDate);
    var endDateString = this.formatDate(this.state.endDate);

    if (startDateString != "") {
      params.append("startDate", startDateString);
    }
    if (endDateString != "") {
      params.append("endDate", endDateString);
    }

    axios
      .post(pathString + "admin/getLogs.php", params)
      .then((response) => {
        var returner = response.data;
        this.setState({
          tableData: response.data,
        });
      })
      .catch((e) => {
        // reset();
      });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.startDate != this.state.startDate ||
      prevState.endDate != this.state.endDate
    ) {
      this.getData();
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          height: "calc(80vh - 120px)",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div style={{ fontSize: 20 }}>Selectați intervalul dorit: </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                label="Data de început"
                value={this.state.startDate}
                onChange={(e) => this.handleDateChange("startDate", e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                label="Data de sfârșit"
                value={this.state.endDate}
                onChange={(e) => this.handleDateChange("endDate", e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <TabelIstoric tableData={this.state.tableData} />
      </div>
    );
  }
}

export default CustomDateIstoric;
