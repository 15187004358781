import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "./../components/Copyright";
import Paper from "@material-ui/core/Paper";
import Background from "./../img/test3.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  smsVerifContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
  },
  link: { textAlign: "center", marginTop: 20 },
}));

export default function SignUp() {
  const classes = useStyles();

  // return (
  //   <Container component="main" maxWidth="xs">
  //     <CssBaseline />
  //     <div className={classes.paper}>
  //       <Avatar className={classes.avatar}>
  //         <LockOutlinedIcon />
  //       </Avatar>
  //       <Typography component="h1" variant="h5">
  //         Înregistrare
  //       </Typography>
  //       <form className={classes.form} noValidate>
  //         <Grid container spacing={2}>
  //           <Grid item xs={12} sm={6}>
  //             <TextField
  //               autoComplete="fname"
  //               name="firstName"
  //               variant="outlined"
  //               required
  //               fullWidth
  //               id="firstName"
  //               label="Prenume"
  //               autoFocus
  //             />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField
  //               variant="outlined"
  //               required
  //               fullWidth
  //               id="lastName"
  //               label="Nume"
  //               name="lastName"
  //               autoComplete="lname"
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <TextField
  //               variant="outlined"
  //               required
  //               fullWidth
  //               id="email"
  //               label="Adresă email"
  //               name="email"
  //               autoComplete="email"
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <TextField
  //               type="number"
  //               variant="outlined"
  //               required
  //               fullWidth
  //               name="phoneNr"
  //               label="Număr de telefon"
  //               id="phoneNr"
  //             />
  //           </Grid>
  //         </Grid>
  //         <Button
  //           type="submit"
  //           fullWidth
  //           variant="contained"
  //           color="primary"
  //           className={classes.submit}
  //         >
  //           Înregistrare
  //         </Button>
  //         <Grid container justify="flex-end">
  //           <Grid item>
  //             <Link href="#" variant="body2">
  //               Aveți deja un cont creat? Autentificați-vă!
  //             </Link>
  //           </Grid>
  //         </Grid>
  //       </form>
  //     </div>
  //     <Box mt={5}>
  //       <Copyright />
  //     </Box>
  //   </Container>
  // );

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Înregistrare
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Prenume"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Nume"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Adresă email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  variant="outlined"
                  required
                  fullWidth
                  name="phoneNr"
                  label="Număr de telefon"
                  id="phoneNr"
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              startIcon={<LockOpenOutlined />}
            >
              Înregistrare
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Aveți deja un cont creat? Autentificați-vă!
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5} style={{ marginTop: "auto", marginBottom: 20 }}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
